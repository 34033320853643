
import React, { useState, memo, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import ElementPositionDraggableView from './ElementPositionDraggableView'

const TestElementPositionModal = ({ handleClose, testElementData, saveTestElementPosition }) => {


  const [elementData, setElementData] = useState([]);

  useEffect(() => {

    let itemsWithPosition = testElementData.filter(
        (item) => item.position !== null && item.position !== undefined
    );
    let itemsWithoutPosition = testElementData.filter(
        (item) => item.position === null || item.position === undefined
    );

    itemsWithoutPosition = shuffleArray(itemsWithoutPosition);

    const maxPosition = itemsWithPosition.length;
    itemsWithoutPosition = itemsWithoutPosition.map((item, index) => ({
        ...item,
        position: maxPosition + index + 1,
    }));

    const combinedItems = [...itemsWithPosition, ...itemsWithoutPosition].sort(
        (a, b) => a.position - b.position
    );

    setElementData(combinedItems);
}, [testElementData]);



const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};


const handleReorder = (reorderedItems) => {
  setElementData(reorderedItems);
};


const saveElementPosition = ()=>{
  saveTestElementPosition(elementData)
}



  return (
    <Modal
      show
      className="second-modal"
      onHide={() => handleClose()}
      animation={true}
      centered
      size={"lg"}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
        Test Element Position
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>

        <div style={{ border: "1px solid gray", marginTop: "5px", borderRadius: "5px" }}>
          <div style={{ padding: "10px" }}>
            <ElementPositionDraggableView
              elementData={elementData}
              onReorder={handleReorder}

            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
          Close
        </Button>

        <Button variant="primary" className="modalButtons" onClick={saveElementPosition}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TestElementPositionModal