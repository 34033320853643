import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Button, Modal, Card, Row, Col } from 'react-bootstrap';
import MainTable from 'components/Table/MainTable';

import API from 'api';
import { CONFIG } from 'constant';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from 'context/app-context';
import { SYSTEM_TIME_ZONE, formattedString } from 'utils';
import { PERSONALISATION_KEY, MESSAGE_MODES, TABLE_QUICK_TOOLS } from 'constant';
import Icon from 'components/Icon';
import Status from 'components/Status/Status';
import chart_Icon from '../../../src/assets/img/chart-simple-solid.png';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import GQAPI from 'views/GraphQL/gqApi';
import Loader from 'components/Loader/Loader';
import api from 'api';
import moment from 'moment';
import { setMessage } from 'store/features/general/generalAction';
import '../../../src/tableStyling.css';
import { selectedDeviceSelector } from 'store/features/general/generalSelectors';
import Select from 'react-select';
import { getTestSource, formatDateMDY, getTestDates } from 'utils';
import { ReactSelectCustomContainer } from 'components/CustomReactSelect/ReactSelectCustomOptionContainer';
import Filter from 'components/Filter';
import { showResultPdf } from 'store/features/resultPdf/reslutPdfSlice';
import { GET_CUST_COLOR } from 'constant';
import { TEST_HISTORY_MODAL_COLUMN } from 'constants/personalization';
import { TEST_DONE_MODAL_COLUMN_DETAILS } from 'constants/personalization';
import { filterSourcesBySwabType } from 'utils';
import MainTableInputBox from 'components/MainTableInputBox/MainTableInputBox';
import { LAB_ID } from 'constant';
import { isLocalhost } from 'utils';
import { showResultReqForm } from 'store/features/resultPdf/reslutPdfSlice';
import { TEST_COLUMNS_FOR_PROVIDER } from 'constants/personalization';
import { hasRejectedReasonChanged } from 'utils';
import { getClientSpecificString } from 'utils';
import { TESTS_DONE_STATUS } from 'constant';
import { fetchAllLocations } from 'store/features/locations/locationsSlice';
import { refLabsSelectors } from 'store/features/locations/locationsSelectors';
import infoIcon from 'assets/img/info-icon.svg';
import TestConfirmationModal from './TestConfirmationModal';
import { userGivenPermission } from 'store/features/authentication/authenticationSelectors';
import { fetchGeneralSettings } from 'store/features/testDictionary/testDictionarySlice';
import { getUUID } from '../../utils';

const TestDoneModal = (props) => {
  const { patient, order, showResult, handleClose, uiType } = props;

  const appContext = useContext(AppContext);

  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(false);

  const [sortBy, setSortBy] = useState('');
  const [sortDescending, setSortDescending] = useState(true);
  const [allSelected, setAllSelected] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [personalize, setPersonalize] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const selectedDevice = useSelector(selectedDeviceSelector);

  const labLocations = useSelector(refLabsSelectors);
  const [sendOutOptions, setSendOutOptions] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const permission = useSelector(userGivenPermission);

  const [confirmationTests, setConfirmationTests] = useState();

  // const sendOutOptions = [{ label: "Magic Owl", value: "850180f3-9cb3-4752-be73-9bc7af1fbb14" }];
  const dispatch = useDispatch();

  const getLabClients = async () => {
    try {
      await dispatch(fetchAllLocations({ siteType: 'lab' }));
    } catch (err) {
      console.log('Error:-', err);
    }
  };

  useEffect(() => {
    const labelArray = [];
    labLocations?.map((item) => {
      labelArray.push({ label: item.name, value: item.id });
    });
    setSendOutOptions(labelArray);
  }, [labLocations]);

  useEffect(() => {
    loadTestData();
    getLabClients();
    orderStatusAcknowledged();
    if (appContext?.user?.isSite()) {
      setPersonalize(TEST_COLUMNS_FOR_PROVIDER);
    } else if (uiType && uiType === 'testHistory') {
      setPersonalize(TEST_HISTORY_MODAL_COLUMN);
    } else {
      setPersonalize(TEST_DONE_MODAL_COLUMN_DETAILS);
    }
    dispatch(fetchGeneralSettings());
  }, []);

  const getStatus = (testData) => {
    if (testData.testStatus) return testData.testStatus;
    if (testData?.sendToID || (testData?.setting?.referenceLab && !testData?.collectedDate))
      return 'sendOut';
    return 'Pending';
  };

  // Get Printer

  const loadTestData = async () => {
    const isProviderLogin = appContext?.user?.isSite();
    if (patient) {
      const tests = await API.getAdminTestList(patient.mdID);

      setTests(
        tests
          .filter((t) => {
            if (t._deleted) return false;
            if (CONFIG.siteID) t.site !== CONFIG.siteID;
            return true;
          })
          .map((item) => {
            const result = isProviderLogin && item.releaseStatus !== 'Confirmed' ? '' : item.result;

            return {
              ...item,
              result,
              testStatus: item?.status?.toLowerCase() === 'pending' ? 'Accepted' : item.status,
              collectedDate: item.createdAt,
              testDate:
                item?.employee_demographics?.orderDate || item?.order?.orderDate || item.createdAt,
              comment: item?.employee_demographics?.comment,
            };
          }),
      );
    } else if (order) {
      const testsToDo = order.testsToDo || {};
      const test = testsToDo.test || [];
      let testsDone = [];
      const ids = test.filter((t) => t.testID).map((t) => t.testID);
      const isSiteUser = appContext?.user?.isSite();

      if (ids.length > 0) testsDone = await API.getMultipleTests({ ids });

      const testWithDate = test.map((t) => {
        const testDoneObj = testsDone.find((obj) => obj?.id === t?.testID);
        const dateOfBirth = order?.dob || patient?.dob;
        const orderDate = order.createdAt;
        // createdAt
        if (testDoneObj) {
          const result =
            isProviderLogin && testDoneObj.releaseStatus !== 'Confirmed' ? '' : testDoneObj.result;
          let baseTestStatus =
            t?.testStatus ||
            (testDoneObj?.status?.toLowerCase() === 'pending' ? 'Accepted' : testDoneObj.status);
          if (
            !(t?.testStatus?.includes('Cancel') || t?.testStatus?.includes('Rejected')) &&
            (baseTestStatus === 'sendOut' || testDoneObj.sendToID)
          ) {
            baseTestStatus = testDoneObj.sendToID ? 'sendOut' : 'Accepted';
          }

          const scanByLabUserID = isSiteUser ? getUUID() : testDoneObj.scanByLabUserID;

          return {
            ...testDoneObj,
            scanByLabUserID,
            result,
            testDate: order.orderDate,
            collectedDate: testDoneObj.createdAt,
            testStatus: baseTestStatus,
            baseTestStatus,
            testTypeID: testDoneObj?.employee_demographics?.testTypeID || '',
            dob: dateOfBirth,
            orderDate,
            preDefineReason: testDoneObj.cancelReason,
            rejectedReason: t.rejectedReason || testDoneObj.note || '',
            isResultEmailed: order.isResultEmailed,
            testDoneModalCall: true,
            fileName: testDoneObj.externalTestFile || null,
          };
        }

        return {
          ...t,
          testStatus: getStatus(t),
          baseTestStatus: t.testStatus === 'Rejected' ? 'Rejected' : 'Pending',
          collectedDate: '',
          test_type: t.label,
          barcode: '',
          resultColor: t.prevTestID ? 'red' : 'green',
          sequenceNo: t.prevTestID || null,
          testDate: order.orderDate,
          testTypeID: t.id,
          dob: dateOfBirth,
          orderDate,
          orderNo: order.orderNo,
          labID: order.labID,
          selectedSource: t?.sources?.slice(0, 1),
          sendToID: t?.sendToID || t?.setting?.referenceLabID,
          sendToName: t?.sendToName || t?.setting?.referenceLab,
        };
      });

      if (ids.length === 0) {
        setAllSelected(true);
        setCheckboxes(testWithDate?.map((t) => t.id));
      } else {
        const pendingtestsIds = test
          ?.filter(
            (item) =>
              !item.testDoneDate && item.testStatus !== 'Rejected' && !!item.scanByLabUserID,
          )
          .map((item) => item.id);

        if (pendingtestsIds?.length > 0) {
          setCheckboxes(pendingtestsIds);
        }
      }

      // Check if any tests are not rejected
      if (testWithDate && testWithDate?.length > 0) {
        setTests(testWithDate || []);
      }
    }
  };

  const orderStatusAcknowledged = async () => {
    try {
      if (!order) return;

      const isCancelStatus = ['Cancel', 'Cancelled', 'Rejected'].includes(order.providerStatus);
      const isSiteUser = appContext?.user?.isSite();
      const isProviderCancelled = order.cancelledBy === 'provider';

      const ackData = {
        orderID: order.id,
        status: order.providerStatus,
        ...(order.status === 'Cancel' && !isSiteUser && isProviderCancelled && { type: 'L' }),
      };

      if (
        (isCancelStatus && isSiteUser && !isProviderCancelled && !order.isAcknowledged) ||
        (ackData.type && !order.isAcknowledged)
      ) {
        await API.acknwoledgedOrder(ackData);
      }
    } catch (ex) {
      console.error('Error in orderStatusAcknowledged:', ex);
    }
  };

  const getNoteName = () => {
    if (patient) return patient?.firstName;
    if (order) return `${order?.firstName} ${order?.lastName}`;
    return '';
  };
  const defaultConfig = useMemo(() => {
    return getClientSpecificString();
  }, []);

  const handleCellClick = async (item, row, event) => {
    if (!row) return;
    switch (item) {
      case 'result':
        if (row.result || row.resultDetails) {
          dispatch(showResultPdf(row));

          // if (
          //   row.labID === LAB_ID.alta ||
          //   row.labID === LAB_ID.caliber ||
          //   row.labID === LAB_ID.seroclinix ||
          //   row.labID === LAB_ID.discovery
          // ) {
          //   dispatch(showResultPdf(row));
          // } else {
          //   const testDetails = await API.getTestByID(row.id, "test");

          //   if (testDetails?.arkstoneID) {
          //     dispatch(showResultPdf(row));
          //     return;
          //   }
          //   showResult(testDetails);
          // }
        }
        break;
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;
      case TABLE_QUICK_TOOLS.notes:
        if (row?.employee_demographics) {
          appContext.openNoteModal({
            ...row,
            objectType: 'test',
            title: getNoteName(),
          });
        }
        break;
    }
  };

  const showReqForm = async (row) => {
    if (row.barcode || row.orderNo) {
      if (order) {
        dispatch(showResultReqForm(order));
      } else {
        // the order is not loaded and calling from test
        dispatch(showResultReqForm({ ...row, orderNo: row.employee_demographics.orderNo }));
      }

      // if (
      //   row.labID === LAB_ID.alta ||
      //   row.labID === LAB_ID.caliber ||
      //   row.labID === LAB_ID.discovery ||
      //   row.labID === LAB_ID.seroclinix ||
      //   row.labID === LAB_ID.inc ||
      //   isLocalhost()
      // ) {
      //   if (order) {
      //     dispatch(showResultReqForm(order));
      //   } else {
      //     // the order is not loaded and calling from test
      //     dispatch(showResultReqForm({ ...row, orderNo: row.employee_demographics.orderNo }));
      //   }
      //   return;
      // }
      // const testDetails = await API.getTestByID(row.id, "test");
      // if (testDetails?.arkstoneID) {
      //   dispatch(showResultReqForm(row));
      //   return;
      // }
      // appContext.showRequisitionForm({ testID: row.id });
    }
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? tests?.map((t) => t.id) : []);

  const handleDifferentStatus = (e, row) => {
    const isAccepted = e.value === 'Accepted';
    const isSendOut = e.value === 'sendOut';
    const isRejected = e.value === 'Rejected';

    // Determine updated fields based on the new status

    const isNoSendOut = isAccepted || isRejected;
    const isNoReason = isAccepted || isSendOut;

    const updatedItem = {
      ...row,
      testStatus: e.value,
      sendToID: isNoSendOut ? null : row.sendToID,
      sendToName: isNoSendOut ? null : row.sendToName,
      rejectedReason: isNoReason ? null : row.rejectedReason,
      fileName: isNoReason ? null : row.fileName,
    };

    // Update the tests array with the new test status
    const updatedTests = tests.map((item) =>
      item.id === row.id ? { ...item, ...updatedItem } : item,
    );
    setTests(updatedTests);
    setCheckboxes([...checkboxes, updatedItem.id]);
    // Set selectedRow for further processing if the status is sendOut or Rejected
    if (isSendOut || isRejected) {
      setSelectedRow({
        ...updatedItem,
        sendToName: row?.sendToName || row?.setting?.referenceLab,
        sendToID: row?.sendToID || row?.setting?.referenceLabID,
      });
    }
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const quickTools = [TABLE_QUICK_TOOLS.checkbox, TABLE_QUICK_TOOLS.notes];

  const processRejectedTest = async (params) => {
    const testToPerformList = tests.filter((t) => checkboxes.includes(t.id));

    const hasRejectedTest = testToPerformList?.some(
      (t) => t.testStatus === 'Rejected' && !t.collectedDate,
    );

    // if (hasRejectedTest) {
    //   const prevData = order?.testsToDo?.test;
    //   if (hasRejectedReasonChanged(prevData, tests)) {
    //     setLoading(true);
    //     await GQAPI.updateRejcetedOrder({
    //       dbOrder: order,
    //       selectedTestIds: checkboxes,
    //       user: appContext?.user,
    //       updatedTestsData: tests,
    //     });
    //     setLoading(false);
    //   }
    // }

    handlePrintClick(params);
  };

  const isDisableControls = (item) => !!item.scanByLabUserID;

  const getConfirmation = (params) => {
    if (order.status === 'Cancel') return;

    let testToPerformList = [];
    if (params?.rowData?.length > 0) {
      testToPerformList.push(params?.rowData);
    } else {
      testToPerformList = tests.filter((t) => checkboxes.includes(t.id));
    }

    const unProcessedtests = testToPerformList?.filter((item) => !isDisableControls(item));

    if (unProcessedtests?.length > 0) {
      setConfirmationTests({
        isPrintDisabled: params.isPrintDisabled,
        lab: unProcessedtests.map((item) => item?.sendToName),
        rowData: params?.rowData,
      });
      return;
    }
    handleAllPrintTests(params);
  };

  const handleAllPrintTests = async (params) => {
    let testToPerformList = [];

    testToPerformList = tests.filter((t) => checkboxes.includes(t.id));

    if (params?.isPrintDisabled && defaultConfig?.isTestCreationFromEmployee) {
      const testToReceive = testToPerformList.filter((t) => !t.scanByLabUserID && !t.result);

      if (testToReceive.length > 0) {
        API.changesTestStatus(testToReceive);
        dispatch(setMessage('Received sucessfully', MESSAGE_MODES.success));
      } else if (testToReceive.length === 0) {
        dispatch(setMessage('No Test to Process', MESSAGE_MODES.error));
      }
      handleClose();
      return;
    }

    processRejectedTest(params);
  };

  const handlePrintClick = async (paramsData) => {
    if (defaultConfig?.isTestCreationFromEmployee) {
      const client = appContext.clients.find((f) => f.id === CONFIG.clientID);
      paramsData = { ...paramsData, labelPrintingQty: client?.setting?.labelPrintingQty || 1 };
    }

    setLoading(true);
    let testToPerformList = [];
    const singleTestPrint = paramsData?.rowData || confirmationTests?.rowData;
    if (singleTestPrint) {
      testToPerformList.push(singleTestPrint);
    } else {
      // if press ok then consider all tests no filter will apply
      testToPerformList = tests.filter((t) => checkboxes.includes(t.id));
    }

    const bracodeList = testToPerformList.filter((t) => t.barcode);

    // if (bracodeList.length > 0 && !paramsData?.isPrintDisabled) {
    //   bracodeList.forEach((item) =>
    //     appContext.printBarcode({ ...item, labelPrintingQty: paramsData?.labelPrintingQty || 1 })
    //   );
    //   setCheckboxes([]);
    //   setLoading(false);
    // }

    if (bracodeList.length > 0 && !paramsData?.isPrintDisabled) {
      bracodeList.forEach((item) => {
        const qty = paramsData?.labelPrintingQty || 1;
        for (let i = 0; i < qty; i++) {
          appContext.printBarcode(item);
        }
      });
      setCheckboxes([]);
      setLoading(false);
    }

    try {
      const { user = {}, userLabName } = appContext;

      const { eligibilityStatus } = order;

      const testsToPerform = testToPerformList
        .filter((t) => !t.barcode)
        .map((t) => {
          const itemSelectedSources = [
            ...new Set(t.selectedSource.map((src) => src?.label?.trim())),
          ].join(', ');
          return {
            ...t,
            isSelected: t.barcode ? false : true,
            selectedSource: itemSelectedSources,
            sendToID: t?.sendToID,
            sendToName: t?.sendToName,
          };
        });

      const testToReceive = testToPerformList.filter((t) => t.barcode && !t.scanByLabUserID);

      if (testToReceive.length > 0) {
        console.log('testToReceive', testToReceive);
        await api.receiveTests({ testDate: moment().format('YYMMDD'), tests: testToReceive });
        setLoading(false);
      }

      if (testsToPerform.length === 0) {
        setLoading(false);
        setCheckboxes([]);
        if (paramsData?.isModalClose) {
          handleClose();
        }
        return;
      }

      const { testCreatedAt, testDate } = getTestDates(order);

      let isCreatedByLab = appContext?.user?.isSite() ? 'provider' : 'lab';

      const params = {
        labName: userLabName,
        order: {
          ...order,
          testsToDo: { test: testsToPerform },
          employee_demographics: {
            ...order.employee_demographics,
            dob: moment(order.employee_demographics.dob).format('MMDDYYYY'),
            worker: '',
            possibleexposure: '',
            confirmedexposure: '',
            relatedsymptoms: '',
            symptoms: '',
            othercomment: '',
            comment: '',
            docUploaded: false,
            isReview: false,
            venipuncture: false,
            distance: 0,
            isEligible: eligibilityStatus?.status?.toLowerCase() === 'eligibile',
            stat: false,
          },
          labID: CONFIG.whiteLabelID,
          labName: userLabName,
          createSource: 'portal',
          timezone: SYSTEM_TIME_ZONE,
          createdByPhone: user?.phone_number,
          testCreatedAt,
          testDate,
          createdBy: user?.sub,
          createdByName: user?.name,
          tester_name: user?.name,
          isCreatedByLab: isCreatedByLab,
        },
      };

      const res = await api.createMulipleTests(params);

      const updatedTests = tests.map((test) => {
        const processedTest = res[test.label]?.test;
        if (processedTest) {
          return {
            ...test,
            ...processedTest,
            dob: order.dob,
            testStatus: test.testStatus || processedTest.status,
            testTypeID: test.id,
            testDate: order.orderDate,
            collectedDate: processedTest.createdAt,
            orderDate: order.createdAt,
          };
        }
        return test;
      });

      // tests for printings

      const testForPrinting = tests
        .filter((test) => res[test.label]?.test) // Filter out only the tests that have processedTest
        .map((test) => {
          const processedTest = res[test.label].test;
          return {
            ...test,
            ...processedTest,
            dob: order.dob,
            testStatus: test.testStatus || processedTest.status,
            testTypeID: test.id,
            testDate: order.orderDate,
            collectedDate: processedTest.createdAt,
            orderDate: order.createdAt,
          };
        });

      setTests(updatedTests);

      // // Print barcodes for newly processed tests
      testForPrinting.forEach((test) => {
        if (!paramsData?.isPrintDisabled) {
          if (test.barcode) {
            const qty = paramsData?.labelPrintingQty || 1;
            for (let i = 0; i < qty; i++) {
              appContext.printBarcode(item);
            }
          }
        }
      });

      setLoading(false);
      setCheckboxes([]);
      if (paramsData?.isModalClose) {
        handleClose();
      }
    } catch (error) {
      console.log('Err', error);
      setLoading(false);
      setCheckboxes([]);
      dispatch(setMessage('Fail to print the Label', MESSAGE_MODES.error));
    }
  };

  const downloadRequisitionFile = async () => {
    try {
      await appContext.downloadRequisition({ filteredTests: tests, allSelected, checkboxes });
    } catch (error) {
      console.log('error', error);
      appContext.showErrorMessage('Fail to downlaod');
    }
  };

  const handleInputComment = (e, row) => {
    const updatedItem = {
      ...row,
      labComment: e.target.value,
    };

    const updatedTests = tests.map((item) =>
      item.id === row.id ? { ...item, ...updatedItem } : item,
    );

    setTests(updatedTests);
  };

  const handleInputRejectReason = (data) => {
    const updatedItem = {
      ...selectedRow,
      ...data,
    };

    setSelectedRow(updatedItem);
  };

  const handleSendOut = (e) => {
    const updatedItem = {
      ...selectedRow,
      sendToName: e.label,
      sendToID: e.value,
      rejectedReason: '',
    };

    setSelectedRow(updatedItem);
  };

  const formatTestStatus = (row) => {
    const statusMap = {
      Rejected: { label: 'Reject', value: 'Rejected' },
      sendOut: { label: 'Send Out', value: 'sendOut' },
      Cancel: { label: 'Cancel', value: 'Cancel' },
      Cancelled: { label: 'Cancel', value: 'Cancel' },
    };
    return statusMap[row.testStatus] || { label: 'Accept', value: 'Accepted' };
  };

  const sourceOptions = [
    { label: 'Accept', value: 'Accepted' },
    { label: 'Reject', value: 'Rejected' },
    { label: 'Send Out', value: 'sendOut' },
  ];

  const customRenderTD = (item, row, index) => {
    const isTestDone = !!row?.collectedDate;
    if (item.itemKey === 'req_form') {
      return (
        <td onClick={() => showReqForm(row)} className="cursor-pointer text-center">
          <img src={chart_Icon} alt="chart Icon" width={20} height={20} />
        </td>
      );
    }
    if (item.itemKey === 'name') {
      return <td className="cursor-pointer text-center">{row?.name || row?.test_type || ''}</td>;
    }
    if (item.itemKey === 'print' && permission?.test?.createTest) {
      return (
        <td className="cursor-pointer text-center">
          <i
            className="fas fa-print mx-2 cursor-pointer"
            style={{
              color: !selectedDevice || row.testStatus === 'Rejected' ? 'grey' : 'green',
              fontSize: '24px',
              opacity: !selectedDevice || row.testStatus === 'Rejected' ? '0.5' : '',
            }}
            onClick={() =>
              getConfirmation({ rowData: row, isPrintDisabled: false, isModalClose: false })
            }
          ></i>
        </td>
      );
    }
    if (item.itemKey === 'testStatus') {
      const orderTestStatus = row['baseTestStatus'];
      let rowTitle = orderTestStatus;
      if (
        orderTestStatus === 'Cancel' ||
        orderTestStatus === 'Rejected' ||
        orderTestStatus === 'Cancelled'
      ) {
        rowTitle = row.rejectedReason || row.preDefineReason;
      }

      return (
        <div className="star-icon-wrapper cursor-pointer text-center mt-3">
          <Status
            type="circle"
            size="md"
            color={TESTS_DONE_STATUS[orderTestStatus]}
            title={rowTitle}
            crossIcon={orderTestStatus}
          />
        </div>
      );
    }
    if (item.itemKey === 'pcrStatus') {
      return (
        <td
          className="ellipsis"
          style={{ textAlign: 'center', color: GET_CUST_COLOR[row.pcrStatus] || '' }}
          title={row.pcrStatus || '-'}
          key={`pcrStatus_${row.id}`}
        >
          {row.pcrStatus || '-'}
        </td>
      );
    }
    if (item.itemKey === 'comment') {
      return (
        <MainTableInputBox
          value={row?.employee_demographics?.comment || row?.labComment}
          handleBlur={(e) => handleInputComment(e, row)}
          disabled={isTestDone || appContext?.user?.isSite()}
        />
      );
    }

    if (item.itemKey === 'testAction') {
      let testDoneSource = [];
      if (row?.employee_demographics?.swabtype) {
        testDoneSource = filterSourcesBySwabType(
          getTestSource(row.sources),
          row?.employee_demographics?.swabtype,
        );
      }

      return (
        <td className="text-center">
          <div style={{ position: 'relative' }}>
            <div className="d-flex justify-content-center">
              <Select
                options={
                  defaultConfig.isTestCreationFromEmployee
                    ? sourceOptions.filter((opt) => opt.value !== 'sendOut')
                    : sourceOptions
                }
                blurInputOnSelect={true}
                styles={{ width: '200px !important' }}
                menuPlacement="auto"
                className="test-donw-select"
                placeholder="Select Source"
                isDisabled={isDisableControls(row) || order?.status === 'Cancel'}
                value={formatTestStatus(row)}
                onChange={(e) => handleDifferentStatus(e, row)}
                components={{ ValueContainer: ReactSelectCustomContainer }}
              />
            </div>
            {(row?.testStatus === 'sendOut' || row?.testStatus === 'Rejected') && (
              <div
                className="ms-1 d-flex align-items-center"
                style={{ position: 'absolute', top: 13, right: '-20px' }}
              >
                <img
                  src={infoIcon}
                  alt="edit icon"
                  width="20"
                  height={20}
                  onMouseOver={(e) => {
                    e.target.style.cursor = 'pointer';
                  }}
                  title={row?.rejectedReason || row?.sendToName}
                  aria-hidden="true"
                  onClick={(event) => {
                    const formattedRow = {
                      ...row,
                      sendToName: row?.sendToName || row?.setting?.referenceLab,
                      sendToID: row?.sendToID || row?.setting?.referenceLabID,
                    };
                    setSelectedRow(formattedRow);
                  }}
                />
              </div>
            )}
          </div>
        </td>
      );
    }
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };
  const formattedDate = moment(order?.dob).format('MM/DD/YYYY');

  const getName = () => {
    if (patient) {
      return `Test History for ${patient?.firstName} ${patient?.lastName} DOB: ${formatDateMDY(patient.dob)} ${
        patient?.schrID ? `MRN: ${patient.schrID}` : ''
      }`;
    }
    if (order) {
      let testBarcode = tests && tests.length > 0 ? tests[0].barcode : '';
      const nameParts = [
        uiType === 'testManagmentUi' && !appContext?.user?.isSite()
          ? 'Test Management'
          : 'Test Details',
        `${order?.firstName || ''} ${order?.lastName || ''}`.trim(),
        `DOB: ${formattedDate}` || '',
      ];

      if (order?.barcode || testBarcode) {
        nameParts.push(`Acc #${order?.barcode || testBarcode}`);
      }

      const filteredParts = nameParts.filter((part) => part && part.length > 0);

      return filteredParts.join(' - ');
    }
    return 'Test Management';
  };

  const handleSendEmail = async () => {
    const selectedTest = tests.filter((test) => checkboxes.includes(test.id));
    if (!selectedTest.length) {
      return;
    }
    const params = selectedTest.map((t) => {
      return {
        id: t.id,
        sequenceNo: t.sequenceNo,
      };
    });
    await appContext.sendEmailToCrew(params, 'Email');
  };

  const isChangeOccur = (item) => {
    if (!item?.rejectedReason && item.testStatus === 'Rejected') {
      return true;
    }
    if (!item?.sendToName && item.testStatus === 'sendOut') {
      return true;
    }
    return false;
  };

  const searchTerms = ['Test Name', 'Test Status', 'Order Date', 'Accession No'];

  const updateTestsOnConfirm = (params) => {
    const { selectedRow, fileName, isConfirm } = params;
    const updatedTests = tests.map((item) => {
      if (item.id === selectedRow.id) {
        const isRowChanges = isChangeOccur(isConfirm ? selectedRow : item);
        const testStatus = isRowChanges ? 'Accepted' : selectedRow.testStatus;
        // only on confirm update row otherwise not
        const updatedTest = isConfirm
          ? {
              ...item,
              ...selectedRow,
              testStatus,
              labComment: selectedRow.rejectedReason,
              fileName: isRowChanges ? '' : fileName || selectedRow.fileName,
            }
          : {
              ...item,
              testStatus,
            };
        const fieldsToCompare = ['rejectedReason', 'sendToName'];

        // Compare fileName from params with item.fileName and also reason and send name also comapre and then add checkbox
        const hasChanges =
          isConfirm &&
          (fieldsToCompare.some((field) => selectedRow?.[field] !== item?.[field]) ||
            (fileName && fileName !== item.fileName)); // Compare fileName from params with item.fileName
        if (hasChanges) {
          setCheckboxes((prev) => [...prev, item.id]);
        }
        return updatedTest;
      }
      return item;
    });

    setTests(updatedTests);
  };

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        show
        animation={true}
        onHide={() => handleClose()}
        centered
        size={'2xl'}
      >
        <Modal.Header closeButton className="my-0">
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {getName()}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-3  marginTop0 modal-body-bg-gray">
          {loading && <Loader />}

          <div>
            <Row className="m-0">
              <Col md="12" className="p-0">
                <Card className="border-0 mb-1 shows-screen-wrapper">
                  <Card.Body className="p-0">
                    <div className="shows-filter-wrapper">
                      <div className="shows-filter-inner">
                        <Icon
                          handleClick={() => setShowFilter(!showFilter)}
                          title={'Filter'}
                          label={'Filter'}
                          iconType={'filter'}
                        />
                        {uiType === 'testHistory' && (
                          <Icon
                            handleClick={handleSendEmail}
                            disabled={checkboxes.length ? false : true}
                            title={'Send Results'}
                            label={'Send Results'}
                            iconType={'messageIcon'}
                          />
                        )}
                        <Icon
                          handleClick={async () => {
                            if (tests && tests.length > 0) {
                              setAllSelected(!allSelected);
                              toggleCheckboxes(!allSelected);
                            }
                            //  await API.invoicesPDF();
                          }}
                          title={!allSelected ? 'Select All' : 'Deselect All'}
                          label={!allSelected ? 'Select All' : 'Deselect All'}
                          iconType={'selectAllIcon'}
                        />
                        <Icon
                          handleClick={() => downloadRequisitionFile()}
                          disabled={checkboxes.length === 0}
                          title={'Req Forms'}
                          label="Req Forms"
                          iconType={'dw_Icon'}
                        />
                        {permission?.test?.createTest && (
                          <Icon
                            handleClick={() =>
                              getConfirmation({
                                rowData: null,
                                isPrintDisabled: false,
                                isModalClose: true,
                              })
                            }
                            title={'Print Label'}
                            disabled={checkboxes.length > 0 ? false : true}
                            label="Print"
                            iconType={'printerIcon'}
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      {showFilter && (
                        <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <div className="shows-screen-parent">
              <MainTable
                columns={personalize}
                rows={tests}
                selectedRows={checkboxes}
                flipSort={flipSort}
                personalisationKey={PERSONALISATION_KEY.TEST_DETAILS_DONE}
                cssClass="table-noresponsive"
                sortBy={sortBy}
                sortDescending={sortDescending}
                tools={quickTools}
                handleCellClick={handleCellClick}
                customColumnCellRenderer={customRenderTD}
                // dropDownOptions={
                //   uiType === "testManagmentUi" && [
                //     { value: "Accept", title: "Accept" },
                //     //{ value: "Create New", title: "Create New" },
                //     { value: "Reject", title: "Reject" },
                //     // { value: "Send Out", title: "Send Out" },
                //   ]
                // }
                // handleDropDownClick={(type, row) => {
                //   if (type === "Reject") {
                //     setOpenRejectTestModal(true);

                //     setCheckboxes([row.id]);
                //   }
                //   if (type === "Audit Trail") {
                //     appContext.showLogs(row);
                //   } else if (type === "Print Label") {
                //     handlePrintClick(row);
                //   } else if (type === "Requisition Form") {
                //     showReqForm(row);
                //   }
                // }}
                isTestDoneModal
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="flex-row-reverse mt-3">
          {permission?.test?.createTest && (
            <Button
              className="modalButtons"
              variant="primary"
              style={{ width: '85px' }}
              onClick={() => {
                getConfirmation({ rowData: null, isPrintDisabled: true, isModalClose: true });
              }}
            >
              Ok
            </Button>
          )}
          <Button className="modalButtons" variant="primary" onClick={() => handleClose()}>
            Cancel
          </Button>

          {/* <Button className="modalButtons" variant="primary" onClick={updateColumns}>
            Update Columns
          </Button> */}
        </Modal.Footer>
        {selectedRow?.id && (
          <TestConfirmationModal
            show={selectedRow}
            title={
              selectedRow?.testStatus === 'sendOut' ? 'Select Reference Lab' : 'Rejected Reason'
            }
            options={sendOutOptions}
            handleOptions={(e) => handleSendOut(e)}
            handleConfirm={(params) => {
              const { isConfirm, fileName } = params;
              // If not confirmed, update only the testStatus without merging selectedRow
              if (!isConfirm) {
                updateTestsOnConfirm({ fileName, selectedRow, isConfirm }); // `false` to avoid updating the entire `selectedRow`
              } else {
                // If confirmed, update all the fields based on selectedRow
                updateTestsOnConfirm({ fileName, selectedRow, isConfirm }); // `true` to merge selectedRow into the item
              }
              setSelectedRow(null);
            }}
            selectedRow={selectedRow}
            handleClose={() => setSelectedRow(null)}
            handleRejectReason={(e) => handleInputRejectReason(e)}
            employeeID={order?.employeeID}
            orderId={order?.id}
            defaultConfig={defaultConfig}
          />
        )}
        {confirmationTests && (
          <ConfirmationModal
            show
            title="Confirmation"
            handleClose={() => setConfirmationTests(null)}
            handleConfirm={(isConfirm) => {
              if (isConfirm) {
                handleAllPrintTests({
                  isPrintDisabled: confirmationTests?.isPrintDisabled,
                  rowData: confirmationTests?.rowData,
                  isModalClose: true,
                });
              }
              setConfirmationTests(null);
            }}
            isPreRegister
            message={
              <div>{`Do you want to process  ${checkboxes.length === 1 ? 'the test?' : 'these tests?'} `}</div>
            }
          />
        )}
      </Modal>
    </>
  );
};

export default TestDoneModal;
