const TABLE_COLUMN_TYPES = {
  shortDate: 'shortDate',
  text: 'text',
  dateTime: 'dateTime',
  upperCaseText: 'upperCaseText',
  firstLetterCapital: 'firstLetterCapital',
  email: 'email',
  phone: 'phone',
  number: 'number',
  currency: 'currency',
  image: 'image',
  insuranceProvider: 'insuranceProvider',
  result: 'result',
  fixedValue: 'fixedValue',
  json: 'json',
  labelID: 'labelID',
};

const claimSubmitStatusColorOpts = {
  draft: 'gray',
  submitted: '#3939ff',
  partial: 'green',
  rejected: 'red',
  approved: 'green',
  sent: 'green',
  paid: 'green',
  denied: '#ff5f5f',
  cancelled: '#95614c',
  sent: '#3939ff',
  pending: 'orange',
};

export const EMP_PERSONALIZE = [
  {
    itemKey: 'firstName',
    title: 'First Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'lastName',
    title: 'Last Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'email',
    title: 'Email',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    width: '100%',
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: 'phoneNumber',
    title: 'Phone Number',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    width: '100%',
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: 'dob',
    title: 'DOB',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 5,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'street',
    title: 'Address',
    textAlign: 'left',
    textOverflow: 'visible',
    isCheck: true,
    id: 6,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'id_number',
    title: 'ID NUMBER',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 7,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'site_name',
    title: 'Show',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 8,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: 'insurance_name',
    title: 'Payer Carrier',
    textAlign: 'left',
    textOverflow: 'visible',
    isCheck: true,
    id: 9,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'insurance_number',
    title: 'Member Number',
    textAlign: 'left',
    textOverflow: 'visible',
    isCheck: true,
    id: 10,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: 'lastUsed',
    title: 'Last Test Date',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 11,
    width: '100%',
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'totalTest',
    title: 'Total Test',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 12,
    width: '100%',
    type: TABLE_COLUMN_TYPES.number,
  },
];

export const LAB_MEMBER_PERSONALIZE = [
  {
    itemKey: 'firstName',
    title: 'First Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'lastName',
    title: 'Last Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'email',
    title: 'Email',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: 'phoneNumber',
    title: 'Phone Number',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: 'street',
    title: 'Address',
    textAlign: 'left',
    textOverflow: 'visible',
    isCheck: true,
    id: 5,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'street2',
    title: 'Apt/Unit#',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 6,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'city',
    title: 'City',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 7,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'state',
    title: 'State',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 8,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'zip',
    title: 'Zip Code',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 9,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'dob',
    title: 'Date Of Birth',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 10,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'sex',
    title: 'Gender',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 11,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'insurance_name',
    title: 'Payer',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'visible',
    id: 12,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'insurance_number',
    title: 'Member ID',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'visible',
    id: 13,
    type: TABLE_COLUMN_TYPES.upperCaseText,
    customCellRenderer: true,
  },
  {
    itemKey: 'insuranceGroupId',
    title: 'Group ID',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 14,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'updatedAt',
    title: 'Last Update',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 15,
    type: TABLE_COLUMN_TYPES.longDate,
  },
  {
    itemKey: 'eligibilityStatus',
    title: 'Eligibility',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 16,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'status',
    title: 'Status',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 17,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'providerName',
    title: 'Provider',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 18,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'primaryCarePhysician',
    title: 'Primary Care Physician',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 19,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'referringPyhsician',
    title: 'Referring Physician',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 20,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'middleName',
    title: 'Middle Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 21,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'schrID',
    title: 'LTCS ID',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 22,
    type: TABLE_COLUMN_TYPES.labelID,
  },
  {
    itemKey: 'site_name',
    title: 'Client',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'visible',
    id: 23,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    customCellRenderer:true
  },
];

export const PENDING_RESULT_PERSONALIZE = [
  {
    itemKey: 'createdAt',
    title: 'Collected',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
  },

  {
    itemKey: 'firstName',
    title: 'First Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    customCellRenderer: true,
  },

  {
    itemKey: 'lastName',
    title: 'Last Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },

  {
    itemKey: 'dob',
    title: 'DOB',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    type: TABLE_COLUMN_TYPES.shortDate,
  },

  {
    itemKey: 'email',
    title: 'Email',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 5,
    type: TABLE_COLUMN_TYPES.email,
  },

  {
    itemKey: 'phoneNumber',
    title: 'Phone',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 6,
    type: TABLE_COLUMN_TYPES.phone,
  },

  {
    itemKey: 'test_type',
    title: 'Test',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: 'barcode',
    title: 'Accession No',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 8,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },

  {
    itemKey: 'client',
    title: 'External Client',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 9,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },

  {
    itemKey: 'status',
    title: 'Test Status',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 10,
    customCellRenderer: true,
  },

  {
    itemKey: 'pcrStatus',
    title: 'Cust Status',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 11,
    customCellRenderer: true,
  },
  {
    itemKey: 'tester_name',
    title: 'Collected By',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.text,
    id: 12,
  },
  {
    itemKey: 'scanByLabUserName',
    title: 'Received By',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.text,
    id: 13,
  },
  {
    itemKey: 'providerName',
    title: 'Refererring Physician',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    id: 14,
  },
  {
    itemKey: 'orderDate',
    title: 'Order Date',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 15,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'receivedDate',
    title: 'Date Received',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 16,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'sentToLabDate',
    title: 'Date Sent',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 17,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'submittedByName',
    title: 'Sent By',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 18,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'testDate',
    title: 'Test Date',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 19,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'note',
    title: 'Clinical Notes',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 20,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'labComments',
    title: 'Lab Comments',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 21,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'orderNo',
    title: 'Order Number',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 22,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: 'sendToName',
    title: 'Reference Lab',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 23,
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const COMPLETED_RESULT_PERSONALIZE = [
  {
    itemKey: 'resultDateTime',
    title: 'Processed',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
  },

  {
    itemKey: 'firstName',
    title: 'First Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    customCellRenderer: true,
  },

  {
    itemKey: 'lastName',
    title: 'Last Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },

  {
    itemKey: 'dob',
    title: 'DOB',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    type: TABLE_COLUMN_TYPES.shortDate,
  },

  {
    itemKey: 'email',
    title: 'Email',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 5,
    type: TABLE_COLUMN_TYPES.email,
  },

  {
    itemKey: 'phoneNumber',
    title: 'Phone',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 6,
    type: TABLE_COLUMN_TYPES.phone,
  },

  {
    itemKey: 'result',
    title: 'Result',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 7,
    customCellRenderer: true,
  },
  {
    itemKey: 'releaseStatus',
    title: 'Result Release',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 20,
    customCellRenderer: true,
  },

  {
    itemKey: 'test_type',
    title: 'Test',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 8,
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: 'barcode',
    title: 'Accession No',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 9,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },

  {
    itemKey: 'site_name',
    title: 'Client',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 10,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },

  {
    itemKey: 'tester_name',
    title: 'Collected By',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 11,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'scanByLabUserName',
    title: 'Received By',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 12,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'stampByName',
    title: 'Resulted By',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 13,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'verifiedByName',
    title: 'Released By',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 14,
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: 'batch',
    title: 'H&S',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 15,
    customCellRenderer: true,
  },

  {
    itemKey: 'emailSend',
    title: 'Res Sent',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 16,
    customCellRenderer: true,
  },
  {
    itemKey: 'orderDate',
    title: 'Order Date',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 17,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'createdAt',
    title: 'Test Date',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 18,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'createdAt',
    title: 'Collected Date',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 19,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'clinicalcomment',
    title: 'Clinical Notes',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 20,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'labComments',
    title: 'Lab Comments',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 21,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'orderNo',
    title: 'Order Number',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 22,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: 'arkstoneStatus',
    title: 'Ark Ready',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 23,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: 'sendToName',
    title: 'Reference Lab',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 24,
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const TEST_MANAGEMENT_PERSONALIZATION = [
  {
    itemKey: 'name',
    title: 'Test Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'typeOfTest',
    title: 'Result Type',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'icdCodes',
    title: 'ICD Code',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
    textOverflow: 'none',
  },
  {
    itemKey: 'category',
    title: 'Department',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: 'source',
    title: 'Source',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: 'lastUsed',
    title: 'Last Used',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 6,
    type: TABLE_COLUMN_TYPES.dateTime,
  },

  {
    itemKey: 'isARKStone',
    title: 'Arkstone',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
    cssClass: 'linkedText',
    customCellRenderer: true,
  },
  {
    itemKey: 'isActive',
    title: 'Active',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 8,
    type: TABLE_COLUMN_TYPES.text,
    cssClass: 'linkedText',
    customCellRenderer: true,
  },
];

export const ORDER_PERSONALIZE = [
  {
    itemKey: 'createdAt',
    title: 'Date of Order',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'firstName',
    title: 'First Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'lastName',
    title: 'Last Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'dob',
    title: 'DOB',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'sex',
    title: 'Gender',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'street2',
    title: 'Apt/Unit',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 6,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'tests',
    title: 'Test #',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: 'test_type',
    title: 'Test Name',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 8,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: 'barcode',
    title: 'Accession #',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 9,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: 'site_name',
    title: 'Client',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 10,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    customCellRenderer: true,
  },
  {
    itemKey: 'status',
    title: 'Order Status',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 11,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: 'orderDate',
    title: 'Test Date',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 12,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'eligibilityStatus',
    title: 'Eligibility',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 13,
    type: TABLE_COLUMN_TYPES.text,
    cssClass: 'linkedText',
  },
  {
    itemKey: 'providerName',
    title: 'Refererring Physician',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 14,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'clincialComments',
    title: 'Clinical Notes',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 16,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'orderNo',
    title: 'Order Number',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 17,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: 'isResultEmailed',
    title: 'Result',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 18,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: 'providerStatus',
    title: 'Order Status',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 19,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: 'cancelledReason',
    title: 'Lab Comment',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 20,
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const ORDER_IMPORT_MODAL_PERSONALIZE = [
  {
    itemKey: 'test_date',
    title: 'Test Date',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
    columnWidth: '60px',
    customCellRenderer: true,
  },
  {
    itemKey: 'first_name',
    title: 'First Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
    columnWidth: '60px',
    isEmployee: true,
  },
  {
    itemKey: 'last_name',
    title: 'Last Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
    columnWidth: '60px',
    isEmployee: true,
  },
  {
    itemKey: 'dob',
    title: 'DOB',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    type: TABLE_COLUMN_TYPES.shortDate,
    columnWidth: '60px',
    isEmployee: true,
  },
  {
    itemKey: 'patientCreated',
    title: 'Patient',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
    cssClass: 'linkedText',
    customCellRenderer: true,
    columnWidth: '60px',
    isEmployee: true,
  },
  {
    itemKey: 'orderCreated',
    title: 'Order',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 6,
    type: TABLE_COLUMN_TYPES.text,
    cssClass: 'linkedText',
    customCellRenderer: true,
    columnWidth: '60px',
  },
  {
    itemKey: 'eligibility',
    title: 'Eligibility',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
    cssClass: 'linkedText',
    customCellRenderer: true,
    columnWidth: '60px',
    isEmployee: true,
  },
  {
    itemKey: 'reason',
    title: 'Reason',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 8,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    columnWidth: '130px',
    isEmployee: true,
  },
];

export const TEST_PANEL_PERSONALIZE = [
  {
    itemKey: 'name',
    title: 'Element Name',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    width: '100%',
    id: 1,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: false,
  },

  // {
  //   itemKey: "units",
  //   title: "Unit",
  //   textAlign: "",
  //   isCheck: true,
  //   textOverflow: "none",
  //   id: 2,
  //   width: "100%",
  //   customCellRenderer: true,
  // },
  // {
  //   itemKey: "sources",
  //   title: "Sources",
  //   textAlign: "",
  //   isCheck: true,
  //   textOverflow: "none",
  //   id: 5,
  //   width: "100%",
  //   customCellRenderer: true,
  //   type: TABLE_COLUMN_TYPES.text,
  // },
  {
    itemKey: 'antibioticResistanceType',
    title: 'Element Type',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 5,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  //
  // {
  //   itemKey: "refInterval",
  //   title: "Ref Interval",
  //   textAlign: "",
  //   isCheck: true,
  //   textOverflow: "none",
  //   id: 3,
  //   type: TABLE_COLUMN_TYPES.text,
  //   width: "100%",
  //   customCellRenderer: false,
  // },
  // {
  //   itemKey: "action",
  //   title: "Action",
  //   textAlign: "right",
  //   isCheck: true,
  //   textOverflow: "none",
  //   id: 4,
  //   type: TABLE_COLUMN_TYPES.text,
  //   width: "100%",
  //   customCellRenderer: true,
  // },
];

export const CREATE_TEST_PERSONALIZE = [
  {
    itemKey: 'name',
    title: 'Element Name',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    width: '100%',
    id: 1,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: false,
  },

  {
    itemKey: 'units',
    title: 'Unit',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    width: '100%',
    customCellRenderer: true,
  },
  {
    itemKey: 'sources',
    title: 'Sources',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 5,
    width: '100%',
    customCellRenderer: true,
    type: TABLE_COLUMN_TYPES.text,
  },
  // {
  //   itemKey: "refInterval",
  //   title: "Ref Interval",
  //   textAlign: "",
  //   isCheck: true,
  //   textOverflow: "none",
  //   id: 3,
  //   type: TABLE_COLUMN_TYPES.text,
  //   width: "100%",
  //   customCellRenderer: false,
  // },
  {
    itemKey: 'antibioticResistanceType',
    title: 'Element Type',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 6,
    width: '100%',
    customCellRenderer: false,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'action',
    title: 'Action',
    textAlign: 'right',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
    width: '100%',
    customCellRenderer: true,
  },
];

export const CREATE_TEST_GENERIC_PERSONALIZE = [
  {
    itemKey: 'name',
    title: 'Pannel Name',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    width: '100%',
    id: 1,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    customCellRenderer: false,
  },

  {
    itemKey: 'sources',
    title: 'Sources',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 5,
    width: '100%',
    customCellRenderer: true,
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const ASSOCIATED_PANEL_PERSONALIZE = [
  {
    itemKey: 'serialNo',
    title: 'Serial',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    width: '100%',
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: false,
  },
  {
    itemKey: 'name',
    title: 'Name',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    width: '100%',
    id: 1,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    customCellRenderer: false,
  },

  {
    itemKey: 'unit',
    title: 'Unit',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    width: '100%',
    customCellRenderer: true,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'sources',
    title: 'Sources',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    width: '100%',
    customCellRenderer: true,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'refInterval',
    title: 'Ref Interval',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
    width: '100%',
    customCellRenderer: false,
  },
  {
    itemKey: 'status',
    title: 'Status',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
    width: '100%',
    customCellRenderer: true,
  },
];

export const SHOW_TEST_PERSONALIZE = [
  {
    itemKey: 'name',
    title: 'Panel',
    textAlign: '',
    isCheck: true,
    id: 1,
    type: TABLE_COLUMN_TYPES.upperCaseText,
    columnWidth: '90px',
    customCellRenderer: false,
  },

  {
    itemKey: 'testResult',
    title: 'Result',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
    columnWidth: '60px',
    customCellRenderer: true,
  },
  {
    itemKey: 'testResultMarks',
    title: 'CT Value',

    textAlign: 'center',

    isCheck: true,
    textOverflow: 'none',
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
    columnWidth: '60px',
    customCellRenderer: true,
  },

  {
    itemKey: 'unit',
    title: 'Unit',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
    columnWidth: '60px',
    customCellRenderer: true,
  },
  {
    itemKey: 'refInterval',
    title: 'Ref Interval',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
    columnWidth: '60px',
    customCellRenderer: false,
  },
];

export const TEST_DICTIONARY_PERSONALIZE = [
  {
    itemKey: 'label',
    title: 'Code',
    isCheck: true,
    textOverflow: 'visible',
    id: 0,
    width: '100%',
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: 'value',
    title: 'Title',
    isCheck: true,
    textOverflow: 'visible',
    id: 1,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
];

// add new
export const PROCEDURE_CODE_PERSONALIZE = [
  {
    itemKey: 'code',
    title: 'Procedure Code',
    isCheck: true,
    textOverflow: 'visible',
    id: 1,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'internalCode',
    title: 'Alias',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 2,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'description',
    title: 'Procedure Code Desc',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 3,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'charges',
    title: 'Internal Charge',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 4,
    width: '100%',
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'referenceCharge',
    title: 'Reference Charge',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 5,
    width: '100%',
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'cashCharge',
    title: 'Cash Charge',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 6,
    width: '100%',
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'inNeworkCharge',
    title: 'In Network Charge',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 7,
    width: '100%',
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'outNetworkCharge',
    title: 'Out Network Charge',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 8,
    width: '100%',
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'effectiveDate',
    title: 'Effective Date',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 9,
    width: '100%',
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'retiredDate',
    title: 'Retired Date',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 10,
    width: '100%',
    type: TABLE_COLUMN_TYPES.shortDate,
  },

  {
    itemKey: 'lastUsed',
    title: 'Last Used',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'visible',
    id: 11,
    width: '100%',
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'isActive',
    title: 'Active',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 12,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
];

export const DICTIONARY_PERSONALIZATION = [
  {
    itemKey: 'label',
    title: 'Code',
    isCheck: true,
    textOverflow: 'visible',
    id: 0,
    width: '100%',
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: 'value',
    title: 'Title',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 1,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'settingType',
    title: 'Type',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 2,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
];

// add new
export const DIAGNOSIS_CODE_PERSONALIZE = [
  {
    itemKey: 'code',
    title: 'Diagnosis Code',
    isCheck: true,
    textAlign: 'left',
    textOverflow: 'visible',
    id: 0,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'internalCode',
    title: 'Alias',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'visible',
    id: 1,
    width: '100%',
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: 'title',
    title: 'Diagnosis Code Desc',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'visible',
    id: 2,
    width: '100%',
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'effectiveDate',
    title: 'Effective Date',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'visible',
    id: 3,
    width: '100%',
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'retiredDate',
    title: 'Retired Date',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'visible',
    id: 4,
    width: '100%',
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'isActive',
    title: 'Status',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 5,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
];

export const CLAIM_INVOICE_PERSONALIZE = [
  {
    itemKey: 'invoiceDate',
    title: 'Invoice Date',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    width: '100%',
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'firstName',
    title: 'First Name',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'lastName',
    title: 'Last Name',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'street',
    title: 'Address',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'state',
    title: 'State',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 5,
    width: '100%',
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: 'city',
    title: 'City',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 6,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'invoiceAmount',
    title: 'Amount',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 7,
    width: '100%',
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'discount',
    title: 'Discount',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 8,
    width: '100%',
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'paidAmount',
    title: 'Paid',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 9,
    width: '100%',
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'dueDate',
    title: 'Due Date',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 10,
    width: '100%',
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'paymentMethod',
    title: 'Payment Method',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 11,
    width: '100%',
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: 'status',
    title: 'Status',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 12,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
    colorObj: claimSubmitStatusColorOpts,
    customCellRenderer: true,
  },

  {
    itemKey: 'invoiceNo',
    title: 'Invoice No',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 13,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: 'postGridStatus',
    title: 'Sent',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 14,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: 'sendTo',
    title: 'Sent To',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 15,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
];
export const EMP_CLAIM_PERSONALIZE = [
  {
    itemKey: 'createdAt',
    title: 'Claim Date',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    width: '100%',
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'pat_name_f',
    title: 'First Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'pat_name_l',
    title: 'Last Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'pat_email',
    title: 'Email',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    width: '100%',
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: 'pat_phone',
    title: 'Phone Number',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 5,
    width: '100%',
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: 'pat_addr_1',
    title: 'Address',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 6,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: 'pat_addr_2',
    title: 'Apt/Unit#',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 7,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'pat_city',
    title: 'City',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 8,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'pat_state',
    title: 'State',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 9,
    width: '100%',
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: 'pat_zip',
    title: 'Zip Code',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 10,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'pat_dob',
    title: 'DOB',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 11,
    width: '100%',
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'pat_sex',
    title: 'Gender',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 12,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'payer_name',
    title: 'Payer',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'visible',
    id: 13,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'ins_number',
    title: 'Member ID',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'visible',
    id: 14,
    width: '100%',
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: 'claimNo',
    title: 'Claim ID',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 15,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'total_charge',
    title: 'Claim Amount',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 16,
    width: '100%',
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'adjustedAmount',
    title: 'Adjusted Amount',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 17,
    width: '100%',
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'paidAmount',
    title: 'Paid Amount',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 18,
    width: '100%',
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'eraIds',
    title: 'ERA',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 19,
    width: '100%',
    type: TABLE_COLUMN_TYPES.image,
  },
  {
    itemKey: 'from_date_1',
    title: 'DOS',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'visible',
    id: 20,
    width: '100%',
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'paid_ins_processed',
    title: 'Paid By',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 21,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'status',
    title: 'Status',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 22,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
    colorObj: claimSubmitStatusColorOpts,
    customCellRenderer: true,
  },
  {
    itemKey: 'remote_claimid',
    title: 'B-ID',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 23,
    width: '100%',
    type: TABLE_COLUMN_TYPES.labelID,
  },
  {
    itemKey: 'submittedByName',
    title: 'Processed By',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 24,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'checkDate',
    title: 'Paid Date',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 25,
    width: '100%',
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'paymentCheckNo',
    title: 'Check/ERA #',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 26,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'accNo',
    title: 'Acc. No',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 27,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'invoiceID',
    title: 'Invoice ID',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 28,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
];
export const MEMBER_ORDER_PERSONALIZE = [
  {
    itemKey: 'createdAt',
    title: 'Order Date',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'testQty',
    title: 'Qty Ordered',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'street',
    title: 'Address',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'city',
    title: 'City',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'state',
    title: 'State',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'zip',
    title: 'Zip Code',
    textAlign: 'center',
    textOverflow: 'visible',
    isCheck: true,
    id: 6,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'orderStatus',
    title: 'Order Status',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },
];
export const PROVIDER_PERSONALIZE = [
  {
    itemKey: 'name',
    title: 'Name',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'street',
    title: 'Address',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'city',
    title: 'City',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'state',
    title: 'State',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'zip',
    title: 'Zip',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'phoneNumber',
    title: 'Phone Number',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 6,
    type: TABLE_COLUMN_TYPES.phone,
    customCellRenderer: true,
  },
  {
    itemKey: 'code',
    title: 'Internal Reference Code',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'fax',
    title: 'Fax',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 8,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: 'npi',
    title: 'NPI',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 9,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'npiType',
    title: 'NPI Type',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 10,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'speciality',
    title: 'Speciality',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 11,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'taxid',
    title: 'Taxonomy',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 12,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'orders',
    title: 'Total Orders',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 13,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: 'claimAmount',
    title: 'Claim Amount',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 14,
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'openAmount',
    title: 'Open Amount',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 15,
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'paid',
    title: 'Total Paid',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 16,
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'lastOrder',
    title: 'Last Order',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 17,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
];
export const LAB_CLIENT_PERSONALIZE = [
  {
    itemKey: 'practiceID',
    title: 'ID',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'name',
    title: 'Practice Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
  },
  {
    itemKey: 'street',
    title: 'Address',
    textAlign: 'left',
    isCheck: false,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'phone_number',
    title: 'Phone',
    textAlign: 'left',
    isCheck: false,
    textOverflow: 'none',
    id: 4,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: 'fax',
    title: 'Fax',
    textAlign: 'left',
    isCheck: false,
    textOverflow: 'none',
    id: 5,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: 'email',
    title: 'Email',
    textAlign: 'left',
    isCheck: false,
    textOverflow: 'none',
    id: 6,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'contact_name',
    title: 'Main Contact',
    textAlign: 'left',
    isCheck: false,
    textOverflow: 'none',
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'contactName',
    title: 'Contact Phone',
    textAlign: 'left',
    isCheck: false,
    textOverflow: 'none',
    id: 8,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: 'contactFax',
    title: 'Contact Fax',
    textAlign: 'center',
    isCheck: false,
    textOverflow: 'none',
    id: 9,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: 'contact_email',
    title: 'Contact Email',
    textAlign: 'center',
    isCheck: false,
    textOverflow: 'none',
    id: 10,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'discount',
    title: 'Discount %',
    textAlign: 'center',
    isCheck: false,
    textOverflow: 'none',
    id: 11,
    type: TABLE_COLUMN_TYPES.percentage,
  },
  {
    itemKey: 'commission',
    title: 'Commission %',
    textAlign: 'center',
    isCheck: false,
    textOverflow: 'none',
    id: 12,
    type: TABLE_COLUMN_TYPES.percentage,
  },
  {
    itemKey: 'totalOrders',
    title: 'Total Orders',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 13,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: 'claimAmount',
    title: 'Claim Amount',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 14,
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'openAmount',
    title: 'Open Amount',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 15,
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: 'paid',
    title: 'Paid',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 16,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: 'associatedClients',
    title: 'Associated Providers',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 17,
    type: TABLE_COLUMN_TYPES.number,
    style: {
      color: 'blue',
      cursor: 'pointer',
    },
  },
];

const TEST_DONE_SPECIFIC_COLUMNS = [
  {
    itemKey: 'name',
    title: 'Test Name',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    customCellRenderer: true,
    id: 2,
  },
  // {
  //   itemKey: "comment",
  //   title: "Laboratory Comments",
  //   textAlign: "center",
  //   textOverflow: "none",
  //   type: TABLE_COLUMN_TYPES.text,
  //   isCheck: true,
  //   customCellRenderer: true,
  //   id: 3,
  // },
  {
    itemKey: 'testAction',
    title: 'Action',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.shortDate,
    customCellRenderer: true,
    isCheck: true,
    id: 4,
  },
  {
    itemKey: 'testStatus',
    title: 'Test Status',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    customCellRenderer: true,
    id: 5,
  },
  {
    itemKey: 'result',
    title: 'Result',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.result,
    isCheck: true,
    id: 6,
  },
  {
    itemKey: 'req_form',
    title: 'Req',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    customCellRenderer: true,
    id: 7,
  },
  {
    itemKey: 'print',
    title: 'Print',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.shortDate,
    customCellRenderer: true,
    isCheck: true,
    id: 8,
  },
];

const TEST_HISTORY_SPECIFIC_COLUMNS = [
  {
    itemKey: 'testDate',
    title: 'Test Date',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.shortDate,
    isCheck: true,
    id: 2,
  },
  {
    itemKey: 'name',
    title: 'Test Name',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    customCellRenderer: true,
    id: 3,
  },
  {
    itemKey: 'testStatus',
    title: 'Test Status',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.upperCaseText,
    customCellRenderer: true,
    isCheck: true,
    id: 5,
  },

  {
    itemKey: 'pcrStatus',
    title: 'Custody Status',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.upperCaseText,
    isCheck: true,
    customCellRenderer: true,
    id: 6,
  },
  {
    itemKey: 'result',
    title: 'Result',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.result,
    isCheck: true,
    id: 7,
  },
  {
    itemKey: 'req_form',
    title: 'Req',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    customCellRenderer: true,
    id: 8,
  },
];

export const TEST_COLUMNS_FOR_PROVIDER = [
  {
    itemKey: 'name',
    title: 'Test Name',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    customCellRenderer: true,
    id: 2,
  },
  {
    itemKey: 'comment',
    title: 'Laboratory Comments',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    customCellRenderer: true,
    id: 4,
  },
  {
    itemKey: 'testStatus',
    title: 'Test Status',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.upperCaseText,
    customCellRenderer: true,
    isCheck: true,
    id: 5,
  },
  {
    itemKey: 'result',
    title: 'Result',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.result,
    isCheck: true,
    id: 6,
  },
  {
    itemKey: 'req_form',
    title: 'Req',
    textAlign: 'center',
    textOverflow: 'none',
    type: TABLE_COLUMN_TYPES.text,
    isCheck: true,
    customCellRenderer: true,
    id: 7,
  },
];

// Combining common and specific columns for each table
export const TEST_DONE_MODAL_COLUMN_DETAILS = [...TEST_DONE_SPECIFIC_COLUMNS];
export const TEST_HISTORY_MODAL_COLUMN = [...TEST_HISTORY_SPECIFIC_COLUMNS];

export const ELIGIBILITY_PERSONALIZE = [
  {
    itemKey: 'createdAt',
    title: 'Date Requested',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'firstName',
    title: 'First Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'lastName',
    title: 'Last Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: 'payerId',
    title: 'Payer',
    textAlign: '',
    isCheck: true,
    textOverflow: 'visible',
    id: 6,
    type: TABLE_COLUMN_TYPES.insuranceProvider,
  },
  {
    itemKey: 'medicalNo',
    title: 'Member ID #',
    textAlign: '',
    isCheck: true,
    textOverflow: 'visible',
    id: 7,
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },

  {
    itemKey: 'groupId',
    title: 'Group ID #',
    textAlign: '',
    isCheck: true,
    textOverflow: 'visible',
    id: 9,
    type: TABLE_COLUMN_TYPES.upperCaseText,
    customCellRenderer: true,
  },

  {
    itemKey: 'message',
    title: 'Status',
    textAlign: '',
    isCheck: true,
    textOverflow: 'visible',
    id: 10,
    customCellRenderer: true,
  },
  {
    itemKey: 'userName',
    title: 'User',
    textAlign: '',
    isCheck: true,
    textOverflow: 'visible',
    id: 11,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'copay',
    title: 'Co Payment',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 12,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: 'deductible',
    title: 'Deductible',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 13,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: 'snf',
    title: 'SNF',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 14,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: 'coinsurnace',
    title: 'Coinsurnace',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 15,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: 'planNumber',
    title: 'Plan Number',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 16,
    type: TABLE_COLUMN_TYPES.number,
  },
];

export const LAB_ELIGIBILITY_PERSONALIZE = [
  {
    itemKey: 'createdAt',
    title: 'Date Requested',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: 'firstName',
    title: 'First Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'lastName',
    title: 'Last Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'payerId',
    title: 'Payer',
    textAlign: '',
    isCheck: true,
    textOverflow: 'visible',
    id: 4,
    type: TABLE_COLUMN_TYPES.insuranceProvider,
  },
  {
    itemKey: 'medicalNo',
    title: 'Payer ID #',
    textAlign: '',
    isCheck: true,
    textOverflow: 'visible',
    id: 5,
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },

  {
    itemKey: 'message',
    title: 'Status',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 6,
    customCellRenderer: true,
  },
  {
    itemKey: 'userName',
    title: 'User',
    textAlign: '',
    isCheck: true,
    textOverflow: 'visible',
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'view',
    title: 'Details',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 8,
    type: TABLE_COLUMN_TYPES.image,
  },
  {
    itemKey: 'copay',
    title: 'Co Payment',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 9,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: 'deductible',
    title: 'Deductible',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 10,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: 'snf',
    title: 'SNF',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 11,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: 'coinsurnace',
    title: 'Coinsurance',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 12,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: 'planNumber',
    title: 'Plan Number',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 13,
    type: TABLE_COLUMN_TYPES.number,
  },
];

export const ORDER_DIAG_PERSONALIZATION = [
  {
    itemKey: 'test',
    title: 'Test',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 1,

    columnWidth: '26.66%',
    customCellRenderer: true,
  },
  // {
  //   itemKey: "cpt",
  //   title: "CPT",
  //   textAlign: "",
  //   isCheck: true,
  //   textOverflow: "none",
  //   id: 2,

  //   columnWidth: "16.66%",
  //   customCellRenderer: true,
  // },

  {
    itemKey: 'source',
    title: 'Source',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 3,

    columnWidth: '26.66%',
    customCellRenderer: true,
  },
  {
    itemKey: 'icd',
    title: 'Diagnosis',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 6,

    columnWidth: '26.66%',
    customCellRenderer: true,
  },
  {
    itemKey: 'unit',
    title: 'Units',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 4,

    columnWidth: '16.66%',
    customCellRenderer: true,
  },
  // {
  //   itemKey: "refCharge",
  //   title: "Charge",
  //   textAlign: "",
  //   isCheck: true,
  //   textOverflow: "none",
  //   id: 5,

  //   columnWidth: "16.66%",
  //   customCellRenderer: true,
  // },
];

export const PAYER_INFO_PERSONALIZE = [
  {
    itemKey: 'insuranceCompanyCode',
    title: 'Company',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.text,
    columnWidth: '60px',
    customCellRenderer: false,
  },
  {
    itemKey: 'insuranceCompany',
    title: 'Company Code',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
    columnWidth: '60px',
    customCellRenderer: false,
  },
  {
    itemKey: 'medicalNo',
    title: 'Medical No',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
    columnWidth: '60px',
    customCellRenderer: false,
  },
  {
    itemKey: 'copay',
    title: 'Copay',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    type: TABLE_COLUMN_TYPES.currency,
    columnWidth: '60px',
    customCellRenderer: false,
  },
  {
    itemKey: 'deductible',
    title: 'Deductible',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 5,
    type: TABLE_COLUMN_TYPES.currency,
    columnWidth: '60px',
    customCellRenderer: false,
  },
];

export const MESSAGE_TEMPLATE_PERSONALIZE = [
  {
    itemKey: 'name',
    title: 'Name',
    isCheck: true,
    textOverflow: 'visible',
    id: 0,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'category',
    title: 'Category',
    isCheck: true,
    textOverflow: 'visible',
    id: 1,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'subject',
    title: 'Subject',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 2,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'message',
    title: 'Message',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 3,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const REFERENCE_PERSONALIZE = [
  {
    itemKey: 'name',
    title: 'Lab Name',
    isCheck: true,
    textOverflow: 'visible',
    id: 0,
    width: '100%',
    textAlign: 'left',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'contact_name',
    title: 'Main Contact Name',
    isCheck: true,
    textOverflow: 'visible',
    id: 1,
    width: '100%',
    textAlign: 'left',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'phone_number',
    title: 'Phone',
    isCheck: true,
    textOverflow: 'visible',
    textAlign: 'left',
    id: 2,
    width: '100%',
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: 'email',
    title: 'Email',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'visible',
    id: 3,
    width: '100%',
    type: TABLE_COLUMN_TYPES.email,
  },
];

export const PATIENT_INSURANCE_COLUMN = [
  {
    itemKey: 'insuranceCompanyCode',
    title: 'Payer',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'medicalNo',
    title: 'Member ID',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'copay',
    title: 'Copay',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.currency,
    textOverflow: 'none',
  },
  {
    itemKey: 'deductible',
    title: 'Deductible',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    type: TABLE_COLUMN_TYPES.currency,
  },

  {
    itemKey: 'planStartDate',
    title: 'Effective From',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 5,
    type: TABLE_COLUMN_TYPES.date,
  },
  {
    itemKey: 'planEndDate',
    title: 'Effective To',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 6,
    type: TABLE_COLUMN_TYPES.date,
  },
  {
    itemKey: 'isActive',
    title: 'Active',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 7,
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const PATIENT_DOCUMENT_COLUMNS = [
  {
    itemKey: 'createdAt',
    title: 'Uploaded',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.date,
  },
  {
    itemKey: 'docType',
    title: 'Type',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'docLabel',
    title: 'Label',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  // {
  //   itemKey: "fileName",
  //   title: "File Name",
  //   textAlign: "left",
  //   isCheck: true,
  //   textOverflow: "none",
  //   id: 4,
  //   type: TABLE_COLUMN_TYPES.text,
  // },
];

export const CREATE_TEST_PANEL_PERSONALIZE = [
  {
    itemKey: 'name',
    title: 'Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: 'type',
    title: 'Type',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    customCellRenderer: true,
  },
];

export const TEST_PANELS_PERSONALIZE = [
  {
    itemKey: 'name',
    title: 'Name',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 1,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: 'noOfTest',
    title: 'Panels / Tests',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    customCellRenderer: true,
  },
  {
    itemKey: 'lastUsed',
    title: 'Last Used',
    textAlign: 'left',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.dateTime,
  },
  {
    itemKey: 'code',
    title: 'Internal Panel code',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'visible',
    id: 2,
    width: '100%',
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const keysPickForPanel = ['panelName', 'name', 'testResult', 'testResultMarks', 'unit', 'refInterval'];
export const keysPickForWithouPathogenQualitative = ['name', 'testResult', 'testResultMarks', 'unit', 'refInterval'];
export const keysPickForWithouPathogenQuantitative = ['name', 'testResult', 'unit', 'refInterval'];
export const keysPickForWithPathogenQuantitative = ['panelName', 'name', 'testResult', 'unit', 'refInterval'];

export const PANEL_RESULT_TEST_PERSONALIZE = [
  {
    itemKey: 'panelName',
    title: 'Panel Name',
    textAlign: '',
    isCheck: true,
    id: 1,
    columnWidth: '30px',
    customCellRenderer: true,
  },
  {
    itemKey: 'testResult',
    title: 'Result',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
    columnWidth: '60px',
    customCellRenderer: true,
  },
  {
    itemKey: 'testResultMarks',
    title: 'CT Value',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
    columnWidth: '60px',
    customCellRenderer: true,
  },

  {
    itemKey: 'unit',
    title: 'Units',
    textAlign: 'center',
    isCheck: true,
    textOverflow: 'none',
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
    columnWidth: '60px',
    customCellRenderer: true,
  },
  {
    itemKey: 'refInterval',
    title: 'Ref Interval',
    textAlign: '',
    isCheck: true,
    textOverflow: 'none',
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
    columnWidth: '60px',
    customCellRenderer: false,
    textAlign: 'center',
  },
];
export const TAB_SETTINGS_COLUMN = [
  {
    itemKey: 'printer_name',
    title: 'Printer Name',
    isCheck: true,
    textOverflow: 'visible',
    id: 0,
    width: '100%',
    textAlign: 'left',
    type: TABLE_COLUMN_TYPES.text,
    // customCellRenderer: true,
  },
  {
    itemKey: 'printer_size',
    title: 'Printer Size',
    isCheck: true,
    textOverflow: 'visible',
    id: 1,
    width: '100%',
    textAlign: 'left',
    type: TABLE_COLUMN_TYPES.text,
    // customCellRenderer: true,
  },

  {
    itemKey: 'action',
    title: 'Action',
    isCheck: true,
    textOverflow: 'visible',
    id: 2,
    width: '100%',
    textAlign: 'right',
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
];

export const BARCODE_SETTINGS_COLUMN = [
  {
    itemKey: 'name',
    title: 'Client Name',
    isCheck: true,
    textOverflow: 'visible',
    id: 0,
    width: '500px',
    textAlign: 'left',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'barcodeRangeToShow',
    title: 'Accession# Range',
    isCheck: true,
    textOverflow: 'visible',
    id: 1,
    width: '200px',
    textAlign: 'left',
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: 'action',
    title: 'Action',
    isCheck: true,
    textOverflow: 'visible',
    id: 2,
    width: '100px',
    textAlign: 'right',
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
];
