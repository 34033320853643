import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RESULT_PDF, LAB_ID , CONFIG} from "constant";
import { Storage } from "aws-amplify";
import { env } from "utils";
import { RESULT_PDF_BUCKET, RESULT_PDF_URL, RESULT_REQ_URL } from "./resultPdfConstant";

export const showResultPdf = createAsyncThunk(RESULT_PDF_URL, async (test) => {
  try {

    let fileName = test?.resultPDF || `${test?.barcode}.pdf`;

    if(test.testDoneModalCall){
      fileName =  test?.resultPDF || `${test?.sequenceNo}.pdf`;
    }

    const url = `results/${env}/${test?.clientID}/${fileName}`;

    const result = await Storage.get(url, {
      download: false,
      contentDisposition: "inline",
      bucket: RESULT_PDF_BUCKET,
      contentType: "application/pdf",
    });

    const pdfObj = {
      resultFileUrl: result,
      showPdf: true,
      resultFileType: "pdf",
      resultPdfTitle: `Result For : ${test?.barcode || test?.sequenceNo}`,
    };

    return pdfObj;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log("File does not exist");
      return false;
    } else {
      console.error("Error occurred while checking file existence:", error);
      throw error;
    }
  }
});

export const showResultReqForm = createAsyncThunk(RESULT_REQ_URL, async (test) => {

  try {


    const url =  CONFIG.whiteLabelID === LAB_ID.inc ? `req_form/${env}/${test?.barcode || test?.orderNo}.pdf` : `req_form/${env}/${test?.clientID}/${test?.orderNo}.pdf`;
    console.log('test',url);
    const result = await Storage.get(url, {
      download: false,
      contentDisposition: "inline",
      bucket: RESULT_PDF_BUCKET,
      contentType: "application/pdf",
    });

    const resultPdfTitle = test?.barcode ? `Barcode: ${test.barcode}` : `Order No: ${test?.orderNo}`;

    const pdfObj = {
      resultFileUrl: result,
      showPdf: true,
      resultFileType: "pdf",
      resultPdfTitle: `Requisition Form - ${resultPdfTitle}`,
    };

    return pdfObj;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log("File does not exist");
      return false;
    } else {
      console.error("Error occurred while checking file existence:", error);
      throw error;
    }
  }
});

const initialState = {
  pdfData: {
    resultFileUrl: "",
    showPdf: false,
    resultFileType: "pdf",
    resultPdfTitle: "",
  },
};

const resultPdfSlice = createSlice({
  name: RESULT_PDF,
  initialState,
  reducers: {
    showResultPdf: (state, action) => {
      state.pdfData = action.payload;
    },
    hideResultPdf: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(showResultPdf.fulfilled, (state, action) => {
      state.pdfData = action.payload;
    });
    builder.addCase(showResultReqForm.fulfilled, (state, action) => {
      state.pdfData = action.payload;
    });
  },
});

export const { hideResultPdf } = resultPdfSlice.actions;

export default resultPdfSlice.reducer;
