import React, { useEffect, useState } from "react";
import Select from "react-select";
import api from "api";
import Icon from "components/Icon";
import CreateOptions from "../TestUnitSelector/CreateOptions";
import { ReactSelectCustomContainer } from "components/CustomReactSelect/ReactSelectCustomOptionContainer";

const useOptions = (settingType) => {
  const [options, setOptions] = useState([]);
  const getOptions = async () => {
    try {
      const optionList = await api.getGeneralSetting(settingType);

      setOptions(optionList);
    } catch (error) {
      console.error(`Error fetching ${settingType}:`, error);
    }
  };

  const addOption = async (optionName) => {
    try {
      const isExist = options.some((item) => item.label.toLowerCase() === optionName.toLowerCase());
      if (!isExist) {
        await api.createOption(settingType, optionName);
      }
      getOptions();
    } catch (error) {
      console.error(`Error adding ${settingType}:`, error);
    }
  };

  useEffect(() => {
    getOptions();
  }, [settingType]);

  return { options, addOption };
};

const TestTypeSelect = ({
  settingType,
  onChange,
  value,
  tabIndex = 1,
  label = "Units",
  className = "",
  title = "Create New Unit",
  placeholder = "Select Units",
  updateMainTest,
  required = false,
  isMulti = false,
  defaultValue = null,
  defaultOptions = null,
  showAddIcon = true,
  showNumberSelected=false
}) => {
  const { options, addOption } = useOptions(settingType);

  const [showCreateOptionModal, setShowCreateOptionModal] = useState(false);

  const handelAddOption = (value) => {
    addOption(value);
    updateMainTest(value);
    setShowCreateOptionModal(false);
  };

  return (
    <div className="test-component-element">
      <div className="selector-label-container">
        {label && (
          <p className="test-type-input-label">
            {label}
            {required && <span style={{ color: "red" }}>*</span>}
          </p>
        )}
        {showAddIcon && (
          <Icon
            iconType={"createIconBlue"}
            containerClass={"ms-2 cursor-pointer"}
            innerClass={"test-selector-add-icon"}
            handleClick={() => setShowCreateOptionModal(true)}
          />
        )}
      </div>
      <div className="d-flex align-items-center">
        <Select
          className={`w-100 ${className}`}
          options={defaultOptions ? defaultOptions : options}
          blurInputOnSelect={true}
          menuPlacement={"auto"}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          tabIndex={tabIndex}
          isMulti={isMulti}
          // components={{ ValueContainer: ReactSelectCustomContainer }}
          components={showNumberSelected ? { ValueContainer: ReactSelectCustomContainer } : undefined}

        />
      </div>
      {showCreateOptionModal && (
        <CreateOptions
          handleClose={() => setShowCreateOptionModal(false)}
          title={title}
          label={label}
          handleSaveChange={handelAddOption}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

export default TestTypeSelect;
