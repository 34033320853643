import api from "api";
import Filter from "components/Filter";
import FilterSelect from "components/FilterSelect";
import Icon from "components/Icon";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import PGPagination from "components/Pagination/PGPagination";
import Status from "components/Status/Status";
import MainTable from "components/Table/MainTable";
import TimeFilter from "components/TimeFilter";
import { useInitialRender } from "components/useInitialRender";
import {
  CONFIG,
  NEW_DISCOVERY_ID,
  ORDER_EMPTY_OBJECT,
  ORDER_STATUS,
  TEST_NO_COLOR,
  PAGE_LIMIT,
  stringConstant,
  TABLE_QUICK_TOOLS,
  ORDER,
  EMPLOYEE,
  BIO_LAB_ID,
  LAB_ID,
} from "constant";
import { ORDER_PERSONALIZE } from "constants/personalization";
import { AppContext } from "context/app-context";
import { debounce } from "debounce";
import moment from "moment";
import { useLocation } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "react-activity/dist/Bounce.css";
import { Card, Col, Row } from "react-bootstrap";
import {
  eligibilityMessageColor,
  formatOrders,
  getSelectedColumn,
  parseBooleanValue,
  sortingFilterInLC,
  sortTestList,
  getClientSpecificString,
  formatPersonalization,
  formattedString,
  getTestDates,
} from "utils";
import gqApi from "views/GraphQL/gqApi";
import Loader from "../../components/Loader/Loader";
import RedirectLink from "components/RedirectLink";
import { ALTA_ID } from "constant";
import { personalisationInLC } from "utils";
import { PERSONALISATION_KEY } from "constant";
import PersonalizationModal from "components/Modal/personalizationModal";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProviders } from "store/features/providers/providersSlice";
import { SYSTEM_TIME_ZONE } from "utils";
import { showResultPdf } from "store/features/resultPdf/reslutPdfSlice";
import { showResultReqForm } from "store/features/resultPdf/reslutPdfSlice";
import { isLocalhost } from "utils";
import { selectClient, selectLab } from "store/features/authentication/authenticationSelectors";
import { showConfirmationModal } from "store/features/general/generalAction";
import { setClearConfirmation } from "store/features/general/generalSlice";

const options = [
  {
    title: "Edit",
  },
  {
    title: "Delete",
  },
  {
    title: "View/Update",
  },
];

const arePropsEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) return true;
  return false;
  // Custom comparison logic for props
  // Return true if the props are equal, otherwise return false
};

const OrdersView = () => {
  const [error, setError] = useState("");

  const appContext = useContext(AppContext);
  const location = useLocation();

  const dispatch = useDispatch();

  const [orderTests, setOrderTests] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [openCreator, setOpenCreator] = useState(false);
  const [newOrder, setNewOrder] = useState(ORDER_EMPTY_OBJECT);
  const [orderToDelete, setOrderToDelete] = useState("");
  const [openHipaa, setOpenHipaa] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState();
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [filter, setFilter] = useState({});
  const initialRender = useInitialRender();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const { filterDates } = TimeFilter;
  const [personalize, setPersonalize] = useState([]);
  const permission = useSelector(userGivenPermission);
  const userClientData = useSelector(selectClient);
  const [openRestoredConfirmation, setOpenRestoredConfirmation] = useState(false);
  const [cancelTestData, setCancelTestsData] = useState({ isConfirm: false, reason: "" });
  const labObject = useSelector(selectLab);

  const quickTools = [
    TABLE_QUICK_TOOLS.checkbox,
    permission[ORDER]?.editOrders && TABLE_QUICK_TOOLS.edit,
    permission[ORDER]?.editOrders && TABLE_QUICK_TOOLS.notes,
  ];

  const defaultConfig = useMemo(() => {
    return getClientSpecificString();
  }, []);

  // const searchTerms = [
  //   "First Name",
  //   "Last Name",
  //   `${stringConstant.sequenceNo}`,
  //   "Date of Birth",
  //   "Gender",
  //   `${stringConstant.show}`,
  //   "Ord Status",
  //   "Cancelled Reason",
  //   "Eligibility Status",
  // ];

  const addTermConditionally = (stringConstant, config) => {
    const searchTermsArr = [
      "First Name",
      "Last Name",
      `${stringConstant.sequenceNo}`,
      "Date of Birth",
      "Gender",
      `${stringConstant.show}`,
      "Ord Status",
      "Eligibility Status",
      "Referring Physician",
    ];

    const term = "Cancelled Reason";
    if (CONFIG.clientID === BIO_LAB_ID) {
      const newTerms = [...searchTermsArr];
      newTerms.splice(7, 0, term);
      return newTerms;
    }

    return searchTermsArr;
  };

  const isSiteUser = useMemo(() => {
    return !!appContext?.user?.isSite();
  }, [appContext]);

  const isArchivedOrderUrl = window.location.href.includes("archivedOrders");

  const getProviders = async () => {
    try {
      dispatch(fetchAllProviders({ noShowLoader: true }));
    } catch (err) {
      console.log("getProviders Error:-", err);
    }
  };

  const searchTerms = useMemo(() => {
    return addTermConditionally(stringConstant, defaultConfig);
  }, []);

  const resultTypeOptions = [
    { value: "P/N", label: "P/N" },
    { value: "P/F", label: "P/F" },
  ];
  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
    setOpenHipaa(false);
    setSelectedOrder({});
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredOrders.map((t) => t.id) : []);

  const fetchFromApi = useCallback(
    debounce((filter, timeFilter, page, sortBy, sortDescending) => {
      loadOrders(filter, timeFilter, page, sortBy, sortDescending);
    }, 800),
    []
  );

  useEffect(() => {
    setPageNo(1);

    fetchFromApi({ ...filter }, timeFilter, 1, sortBy, sortDescending);
  }, [filter, sortBy, sortDescending, timeFilter]);

  useEffect(() => {
    if (pageNo > 1) {
      loadOrders(filter, timeFilter, pageNo, sortBy, sortDescending);
    }
  }, [pageNo]);

  useEffect(() => {
    if (initialRender) return;

    if (appContext.eventOrder && appContext.eventOrder !== 0) {
      const eventType = appContext.eventOrder.opType;

      const model = formatOrders(appContext.eventOrder.element, appContext?.user?.isSite());
      const newList = [...orders];

      if (!model.isActive) {
        const prevLength = orders.length;
        const newOrderList = orders.filter((t) => t.id !== model.id);
        setOrders(newOrderList);
        setFilteredOrders(filteredOrders.filter((t) => t.id !== model.id));
        if (totalRecord > 0 && prevLength !== newOrderList.length) {
          setTotalRecord(parseInt(totalRecord) - 1);
        }
        return;
      }

      if (eventType === "INSERT" && (!CONFIG.siteID || model.siteID === CONFIG.siteID)) {
        newList.unshift(model);
        setTotalRecord(parseInt(totalRecord) + 1);
      } else if (eventType === "UPDATE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if (index !== -1) {
          if (!CONFIG.siteID || model.siteID === CONFIG.siteID) {
            newList.splice(index, 1, model);
          } else {
            newList = newList.filter((f) => f.id !== model.id);
          }
        } else {
          if (!CONFIG.siteID || model.siteID === CONFIG.siteID) {
            newList.unshift(model);
          }
        }
      }
      let activeOrders = newList.filter((f) => f.isActive);
      setOrders(activeOrders);
      refreshList(activeOrders);
    }
  }, [appContext?.eventOrder]);

  useEffect(() => {
    if (location.state) {
      let filterTerm = location.state.term;
      let filterTermName = location.state.name;

      setFilter({ ...filter, [filterTerm]: filterTermName });
    }
  }, [location.state]);

  const handleEditOrder = (order) => {
    appContext.showOrderModal({
      ...order,
      // orderDate: moment(order.orderDate).format("YYYY-MM-DDTHH:mm"),
      employee_demographics: {
        ...order.employee_demographics,
        phoneNumber: `${order.countryCode}${order.phoneNumber}`,
      },
      isNew: false,
    });
  };

  const handleUpdateNoteReocrd = async (row) => {
    try {
      setLoading(true);
      await gqApi.updateOrderStatus(row);
      setFilteredOrders((prevTests) => {
        const prevIndex = prevTests.findIndex((t) => t.id === row.id);
        if (prevIndex !== -1) {
          prevTests[prevIndex].notes = row.note;
        }
        return [...prevTests];
      });
      appContext.showSuccessMessage("Note Added Successfully!");
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err);
      setLoading(false);
    }
  };

  const ordersToMap = useMemo(() => {
    let list = filteredOrders ? [...filteredOrders] : [];

    // if (appContext?.user?.isSite()) {

    //   list = list.map((item) => {
    //     console.log('item', item)
    //     const isAnyCanceledTest = item.testsToDo.test.some((test) => test.testStatus === "Cancel");

    //     return {
    //       ...item,
    //       rowColor: isAnyCanceledTest ? "red" : "white",
    //     };
    //   });
    // }

    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredOrders, currentPage]);

  const isDisableCreateTest = (orders, orderId) => {
    if (checkboxes.length === 0) return true;
    const isExist = orders.find((order) => order.id === orderId)?.testsToDo?.test?.filter((t) => !t.isDone);
    return isExist?.length === 0;
  };

  const parseSelectedOption = (id) => {
    if (!id) return null;
    const data = resultTypeOptions.find((f) => f.value === id);
    if (data) return data.label;
    return id;
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const getOrderStatusTitle = (value, row) => {
    if (value === "Cancelled" || value === "Rejected") {
      return row.cancelledReason;
    }
    return value;
  };

  const getPathname = (status) => {
    switch (status) {
      case "Pending":
        return "senttest";
      case "Processed":
        return "alltests";
      default:
        return "";
    }
  };

  const tdFormat = (item, row) => {
    if (item === "isResultEmailed") {
      return row[item] && row["providerStatus"] !== "Cancelled" ? (
        <div
          className="text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
          onClick={async () => {
            dispatch(showResultPdf({ ...row, isSiteUser }));

            // if (
            //   row.labID === LAB_ID.alta ||
            //   row.labID === LAB_ID.caliber ||
            //   row.labID === LAB_ID.discovery ||
            //   row.labID === LAB_ID.seroclinix ||
            //   isLocalhost()
            // ) {
            //   dispatch(showResultPdf({ ...row, isSiteUser }));
            // } else {
            //   const testDetails = await api.getTestByID(row?.testsToDo?.test[0]?.id, "test");

            //   if (testDetails?.arkstoneID) {
            //     dispatch(showResultPdf(row));
            //     return;
            //   }
            //   appContext.showTestResult(testDetails);
            // }
          }}
        >
          View Details
        </div>
      ) : (
        <></>
      );
    }
    if (item === "barcode") {
      return !appContext?.user?.isSite() ? (
        <RedirectLink pathname={getPathname(row["status"])} value={{ name: row["id"], term: "orderID" }}>
          {row[item]}
        </RedirectLink>
      ) : (
        row[item]
      );
    } else if (item === "status" || item === "providerStatus") {
      let orderStatus = row[item] || "Pending";

      return (
        <div className="star-icon-wrapper cursor-pointer">
          <Status
            type="circle"
            size="md"
            title={getOrderStatusTitle(orderStatus, row)}
            color={ORDER_STATUS[orderStatus]}
            crossIcon={orderStatus}
          />
        </div>
      );
    }

    if (item === "tests") {
      const testProcessed = isSiteUser ? row["releasedTest"] : row["processedTest"];
      return (
        <div
          className="cursor-pointer"
          onClick={() => appContext.openPatientTestViewModal({ order: row, type: "order", uiType: "testManagmentUi" })}
        >
          <span className="text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none">
            {`${testProcessed} of ${row[item]}`}
          </span>
        </div>
      );
    }

    if (item === "test_type") {
      return (
        <div
          className="cursor-pointer"
          onClick={() => appContext.openPatientTestViewModal({ order: row, type: "order", uiType: "testManagmentUi" })}
        >
          {row[item]}
        </div>
      );
    }
    if (item === "siteID" || item === "site_name") {
      // const site_name = formattedString(appContext?.getSiteName(row.siteID) || row[item]);
      return <div title={row["site_name"]}>{row["site_name"]}</div>;
      // return (
      //   <a style={{ color: "#42cef5" }} onMouseOver={(e) => (e.target.style.cursor = "pointer")} title={site_name}>
      //     {site_name}
      //   </a>
      // );
    }

    return row[item];
  };
  const tdFormatTitle = (item, row) => {
    if (item === "eligibilityStatus") return row[item]?.message || "Not Checked";
    return row[item];
  };

  const customRenderTD = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormatTitle(item.itemKey, row)}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case "eligibilityStatus":
        appContext.showEligibilityDetails({
          ...row,
          ...row.eligibilityStatus,
          ins_name_f: row.firstName,
          ins_name_l: row.lastName,
          ins_dob: row.dob,
        });
        break;
      case "firstName":
      case "lastName":
        appContext.showPatient({ mdID: row.employeeID, isNew: false });
        break;
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;
      case TABLE_QUICK_TOOLS.edit:
        handleEditOrder(row);
        break;
      case TABLE_QUICK_TOOLS.notes:
        appContext.openNoteModal({
          ...row,
          objectType: "order",
          handleUpdateNoteReocrd,
          title: `${row.firstName} ${row.lastName}`,
        });
        break;
      default:
        break;
    }
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "resultType") {
          return obj[key] && parseSelectedOption(obj[key]).toLowerCase().includes(filters[key]);
        }
        if (key === "whiteLabel") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    // console.log("FROM NESTED FILTER", models);
    return models;
  };

  useEffect(() => {
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.orders?.sortBy) {
      setSortDescending(sortingFilters.orders.sortDescending);
      setSortBy(sortingFilters.orders.sortBy);
    }
    getProviders();
    setPersonalize(personalisationInLC.get(PERSONALISATION_KEY.ORDER_PERSONALIZE));
  }, []);

  useEffect(() => {
    refreshSummary(orders, orderTests);
  }, [orderTests]);

  const loadOrderSummary = async () => {
    const orderSummary = await api.getSummaryFromPG({ summary: "order" });
    setOrderTests(orderSummary.rows);
  };

  const addDateFilter = (dateFilter) => {
    let dateParams = {};

    if (dateFilter) {
      const { start, end } = dateFilter;
      if (start) Object.assign(dateParams, { startDate: start.format("YYYY-MM-DD") });
      if (end) Object.assign(dateParams, { endDate: end.format("YYYY-MM-DD") });
    }
    return dateParams;
  };

  const loadOrders = async (params, dateFilter, page, sortBy, sortByDirection) => {
    setLoading(true);
    const dateParams = addDateFilter(dateFilter);
    const filterParam = { ...params, ...dateParams };
    try {
      Object.assign(filterParam, {
        page: page,
        clientID: CONFIG.clientID,
        gender: params.sex,
        employeeID: location?.state?.employeeID,
        isArchived: isArchivedOrderUrl,
      });

      if (sortBy) {
        Object.assign(filterParam, { orderBy: sortBy, orderByDirection: sortByDirection ? "desc" : "asc" });
      }

      if (CONFIG.siteID) {
        Object.assign(filterParam, { siteID: CONFIG.siteID });
      }

      const pgList = await api.getOrderListFromPG(filterParam);

      setLoading(false);

      let ttlOrders = orders.concat(pgList.rows);
      if (pageNo === 1) {
        setTotalRecord(pgList.total);
        ttlOrders = pgList.rows;
      }

      setOrders(ttlOrders);
      refreshList(ttlOrders);
    } catch (err) {
      console.log("Load Employees Error:-", err);
      setLoading(false);
    }
  };

  const refreshList = (list) => {
    // if (sortBy) {
    //   setFilteredOrders(
    //     sortTestList(
    //       sortBy,
    //       sortDescending,
    //       list,
    //       appContext.getSiteName,
    //       appContext.getLabName,
    //       appContext.getClientName,
    //       appContext.parseResult
    //     )
    //   );
    // } else {
    //   setFilteredOrders(list);
    // }
    setFilteredOrders(list);
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
  };

  const handleCancelConfirm = async () => {
    const { reason } = cancelTestData;
    const orderToCancel = orders.filter(
      (order) => !order.barcode && order.status !== "Cancel" && checkboxes.includes(order.id)
    );

    setCancelTestsData({ isConfirm: false, reason: "" });
    setCheckboxes([]);

    const formattedOrder = orderToCancel.map((item) => ({
      id: item?.id,
      reason,
    }));

    if (formattedOrder.length > 0) {
      setLoading(true);
      await api.cancelOrder({ orders: formattedOrder });

      const testLabel = formattedOrder.length === 1 ? "order" : "orders";
      appContext.showSuccessMessage(`${formattedOrder.length} ${testLabel} have been cancelled successfully`);

      setLoading(false);
    }
  };

  const handleDeleteConfirm = async () => {
    setCancelTestsData({ isConfirm: false, reason: "" });
    setCheckboxes([]);

    try {
      const updateSelectedTestResult = filteredOrders.filter((test) => checkboxes.includes(test.id));
      const ttlTest = updateSelectedTestResult.length;
      if (!ttlTest) {
        return;
      }
      setLoading(true);
      const orderToArchive = updateSelectedTestResult.map((o) => ({
        id: o.id,
        userID: appContext?.user?.sub,
        userName: appContext?.user?.name,
      }));

      if (allSelected) {
        const dateParams = addDateFilter(timeFilter);

        await api.archiveAllOrder({
          ...filter,
          ...dateParams,
          clientID: CONFIG.clientID,
          userID: appContext?.user?.sub,
          userName: appContext?.user?.name,
          timezone: SYSTEM_TIME_ZONE,
        });
      } else {
        await api.archiveOrder(orderToArchive);
      }
      const newOrders = orders.filter((test) => !checkboxes.includes(test.id));

      setOrders(newOrders);
      setFilteredOrders(filteredOrders.filter((test) => !checkboxes.includes(test.id)));
      if (totalRecord > 0) {
        setTotalRecord((prev) => {
          let newTotal = prev - checkboxes.length;
          if (newTotal < 0) newTotal = 0;
          return newTotal;
        });
      }
      setCheckboxes([]);
      if (allSelected) {
        setFilter({});
        setAllSelected(false);
      }

      appContext.showSuccessMessage("Record Archive successfully");

      // if no order to show after archive then call the api and load remaining order
      loadOrders(filter, timeFilter, 1, sortBy, sortDescending);
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const refreshSummary = (data, summary) => {
    if (data.length === 0) return;
    if (summary.length === 0) {
      setFilteredOrders(data);
      return;
    }
    const newList = data.map((c) => {
      const obj = summary.find((x) => x.id === c.id);
      return { ...c, totalTest: obj ? obj.totalTest : 0 };
    });
    setFilteredOrders(newList);
  };

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredOrders.length;
    if (direction === "next" && page === totalPages && totalRecord > 0 && currentRecord < totalRecord) {
      setPageNo(pageNo + 1);
    }
  };

  const handleExportOrder = async () => {
    try {
      setLoading(true);

      const dateParams = addDateFilter(timeFilter);
      const params = {
        ...filter,
        ...dateParams,
        selectedColumn: getSelectedColumn(
          formatPersonalization({ personalize, defaultConfig, userClientData, isSiteUser })
        ),
      };
      const apiRes = await api.exportOrder(params);

      if (apiRes?.fileName) {
        await api.downloadRequisitionZip(
          `${apiRes?.fileName}`,
          `orders_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.xlsx`
        );
      }

      setLoading(false);
      appContext.showSuccessMessage("File download!!!");
    } catch (ex) {
      console.log("Ex", ex);
      setLoading(false);
      appContext.showErrorMessage(error.message);
    }
  };

  const processTestFromOrder = async () => {
    dispatch(setClearConfirmation());
    const orderList = orders.filter((o) => checkboxes.includes(o.id));

    setCheckboxes([]);

    const formattedOrders = orderList.map((order) => {
      const { testCreatedAt, testDate } = getTestDates(order);

      return {
        ...order,
        employee_demographics: {
          ...order.employee_demographics,
          dob: moment(order.employee_demographics.dob).format("MMDDYYYY"),
          worker: "",
          possibleexposure: "",
          confirmedexposure: "",
          relatedsymptoms: "",
          symptoms: "",
          othercomment: "",
          comment: "",
          docUploaded: false,
          isReview: false,
          venipuncture: false,
          distance: 0,
          isEligible: order?.eligibilityStatus?.status?.toLowerCase() === "eligibile",
          stat: false,
        },
        timezone: SYSTEM_TIME_ZONE,
        testCreatedAt,
        testDate,
        createdBy: appContext?.user?.sub,
        createdByName: appContext?.user?.name,
        createSource: "portal",
        shipped: true,
      };
    });

    const employeeData = await api.createBulkTestFromOrder({ orders: formattedOrders });

    console.log("[employeeData]", employeeData);
    appContext.showSuccessBottomMessage("Processed Successfully");

    if (employeeData) {
      employeeData?.forEach((test) => {
        if (test.barcode) {
          appContext.printBarcode(test);
        }
      });
    }
  };

  const handleCreateTestFromOrder = () => {
    if (defaultConfig.isTestCreationFromEmployee) {
      const confirmationModalData = {
        showModal: true,
        onConfirm: () => processTestFromOrder(true),
        onClose: () => dispatch(setClearConfirmation()),
        title: "Confirmation",
        isPreRegister: true,
        message: "Would you like to create the test ordered and print label?",
      };
      dispatch(showConfirmationModal(confirmationModalData));

      return;
    }

    const order = filteredOrders.find((f) => f.id === checkboxes[0]);

    appContext.openPatientTestViewModal({ order: order, type: "order", uiType: "testManagmentUi" });

    // appContext.createTestFromOrder({
    //   ...order,
    //   orderDate: moment(order.orderDate).format("YYYY-MM-DDTHH:mm"),
    //   employee_demographics: {
    //     ...order.employee_demographics,
    //     phoneNumber: `${order.countryCode}${order.phoneNumber}`,
    //   },
    // });
    setCheckboxes([]);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);

    const personalisationData = personalisationInLC.saveAs(data, PERSONALISATION_KEY.ORDER_PERSONALIZE);

    setOpenPersonalizationModal(false);

    await api.saveUserPersonalisation(appContext.user.sub, personalisationData);
  };

  const showReqForm = async (row) => {
    if (row.barcode || row.orderNo) {
      dispatch(showResultReqForm(row));
      return;

      // if (
      //   row.labID === LAB_ID.alta ||
      //   row.labID === LAB_ID.caliber ||
      //   row.labID === LAB_ID.discovery ||
      //   row.labID === LAB_ID.seroclinix ||
      //   row.labID === LAB_ID.inc ||
      //   isLocalhost()
      // ) {
      //   dispatch(showResultReqForm(row));
      //   return;
      // }
      // const testDetails = await api.getTestByID(row.id, "test");
      // if (testDetails?.arkstoneID) {
      //   dispatch(showResultReqForm(row));
      //   return;
      // }
      // appContext.showRequisitionForm({ testID: row.id });
    }
  };

  const handleConfirmRestore = async (isConfirm) => {
    setOpenRestoredConfirmation(false);
    if (!isConfirm) {
      return;
    } else {
      try {
        setLoading(true);
        const ordersList = filteredOrders.filter((order) => checkboxes.includes(order.id));

        const formattedList = ordersList?.map((item) => ({ id: item.id, employeeID: item.employeeID }));
        await api.restoreOrders(formattedList);
        appContext.showSuccessMessage(
          `Successfully Restore ${checkboxes.length} ${checkboxes.length > 1 ? "Orders" : "order"}`
        );
        setCheckboxes([]);
        if (totalRecord > 0) {
          setTotalRecord((prev) => {
            let newTotal = prev - checkboxes.length;
            if (newTotal < 0) newTotal = 0;
            return newTotal;
          });
        }
        setAllSelected(false);
        setLoading(false);

        loadOrders(filter, timeFilter, 1, sortBy, sortDescending);
      } catch (error) {
        appContext.showErrorMessage(error.message);
        console.log(error.message);
        setLoading(false);
      }
    }
  };
  const getDropDownOptions = () => {
    const optionsList = [
      { value: "Logs", title: "Audit Trail" },
      { value: "test", title: "Test History" },
      { value: "req_form", title: "Req Form" },
    ];
    if (appContext?.user?.isSite()) {
      return [{ value: "req_form", title: "Req Form" }];
    }
    return optionsList;
  };

  const handleConfirmation = (isConfirm) => {
    if (!isConfirm) {
      setCancelTestsData({ isConfirm: false, reason: "" });
      setCheckboxes([]);
      return;
    }

    const { actionType, reason, preDefineReason } = cancelTestData;

    switch (actionType) {
      case "Archive":
        return handleDeleteConfirm();
      case "Cancel":
        if (!(reason || preDefineReason)) {
          return appContext.showErrorMessage("Please Enter Reason");
        }
        return handleCancelConfirm();
    }
  };

  const handleClickCancel = () => {
    const orderToCancel = orders.filter(
      (order) => !order.barcode && order.status !== "Cancel" && checkboxes.includes(order.id)
    );

    if (orderToCancel.length === 0) {
      return;
    }

    const message = `Are you sure you want to cancel the ${orderToCancel.length} record${
      orderToCancel.length > 1 ? "s" : ""
    }?`;

    setCancelTestsData({ isConfirm: true, reason: "", actionType: "Cancel", message });
  };

  const showRowColor = (row) => {
    if (
      ["Cancel", "Cancelled", "Rejected"].includes(row?.providerStatus) &&
      row.cancelledBy !== "provider" &&
      !row.isAcknowledged &&
      isSiteUser
    ) {
      return "trDataWrapper isCancelRow";
    }

    if (row.status === "Cancel" && row.cancelledBy === "provider" && !row.isAcknowledged && !isSiteUser) {
      return "trDataWrapper isCancelRow";
    }
    if (row.employee_demographics?.stat && row?.status !== "Processed") {
      return "trDataWrapper isStatRow";
    }
    return "trDataWrapper";
  };

  return (
    <div className="container-fluid">
      {!loading ? (
        <div className="shows-screen-parent">
          <Row className="mx-0">
            <Col md="12">
              <Card className="border-0 mb-1">
                <Card.Body className="p-0">
                  <div className="shows-filter-wrapper">
                    <div className="shows-filter-inner">
                      <Icon
                        handleClick={() => setShowFilter(!showFilter)}
                        title={"Filter"}
                        label={"Filter"}
                        iconType={"filter"}
                      />
                      {!isArchivedOrderUrl && <FilterSelect setTimeFilter={setTimeFilter} />}{" "}
                      <Icon
                        handleClick={() => {
                          if (filteredOrders && filteredOrders.length > 0) {
                            setAllSelected(!allSelected);
                            toggleCheckboxes(!allSelected);
                          }
                        }}
                        title={!allSelected ? "Select All" : "Deselect All"}
                        label={!allSelected ? "Select All" : "Deselect All"}
                        iconType={"selectAllIcon"}
                      />
                      {isArchivedOrderUrl && (
                        <Icon
                          disabled={checkboxes.length === 0}
                          handleClick={() => setOpenRestoredConfirmation(true)}
                          title={"Restore Order"}
                          label={"Restore Order"}
                          iconType={"restoreIcon"}
                        />
                      )}
                      {permission[EMPLOYEE]?.access && (
                        <Icon
                          handleClick={() => setOpenPersonalizationModal(true)}
                          title={"Edit Columns"}
                          label={"Edit Columns"}
                          iconType={"personalizeIcon"}
                        />
                      )}
                      {permission[ORDER]?.archiveOrders === true &&
                        !isArchivedOrderUrl &&
                        (isSiteUser ? (
                          <Icon
                            disabled={checkboxes.length === 0}
                            handleClick={handleClickCancel}
                            title={"Cancel"}
                            label={"Cancel"}
                            iconType={"binIcon"}
                          />
                        ) : (
                          <Icon
                            disabled={checkboxes.length === 0}
                            handleClick={() =>
                              setCancelTestsData({ isConfirm: true, reason: "", actionType: "Archive" })
                            }
                            title={"Archive"}
                            label={"Archive"}
                            iconType={"binIcon"}
                          />
                        ))}
                      <Icon
                        disabled={ordersToMap.length === 0}
                        handleClick={handleExportOrder}
                        title={"Export to Excel"}
                        label="Export"
                        iconType={"XLSIcon"}
                      />
                      {permission[ORDER]?.loadOrders === true && !isArchivedOrderUrl && (
                        <Icon
                          handleClick={() => appContext.showLoadOrderModal(true)}
                          iconType={"importIcon"}
                          label={"Load Orders"}
                          title={"Load Orders"}
                        />
                      )}
                      {/* {permission[ORDER]?.addOrders === true && (
                        <Icon
                          handleClick={() => {
                            setCheckboxes([]);
                            appContext.showOrderModal(newOrder);
                          }}
                          title={"Create Order"}
                          label={"Create Order"}
                          iconType={"createIcon"}
                        />
                      )} */}
                      {permission?.test?.createTest === true && !appContext?.user?.isSite() && !isArchivedOrderUrl && (
                        <Icon
                          handleClick={handleCreateTestFromOrder}
                          title={"Create Test"}
                          label={"Create Test"}
                          iconType={"createIcon"}
                          disabled={isDisableCreateTest(ordersToMap, checkboxes[0])}
                        />
                      )}
                    </div>
                  </div>
                  <div>{showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}</div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-0">
            <Col md="12">
              <div
                className={`strpied-tabled-with-hover bg-transparent border-0 ${
                  ordersToMap?.length === 1 || ordersToMap?.length === 2 ? "order-details-table" : ""
                }`}
              >
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <Col md="6">
                      <Card.Title>
                        <div className="">
                          Orders:<span className="ps-2"> {totalRecord}</span>
                        </div>
                      </Card.Title>
                    </Col>
                    <Col md="6">
                      <PGPagination
                        currentPage={currentPage}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                        totalRecord={totalRecord}
                        currentRecord={filteredOrders.length}
                        showSelectedRecord
                        totalSelected={checkboxes.length}
                      />
                    </Col>

                    {/* <div className="buttonHeader ordersButtonHeader justify-content-between mb-1">
                      <div className="ordersBtnWrapper">
                        <div className="buttonHeader ordersButtonHeader">
                          <Button className="headerButton mb-0" onClick={() => setShowFilter(!showFilter)}>
                            Filter
                          </Button>
                        </div>
                      </div>
                      <Button className="createButtons" onClick={() => setOpenCreator(true)}>
                        Create New Order
                      </Button>
                    </div>
                    <div>
                      {showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}
                    </div> */}
                  </Row>
                </Card.Header>

                <div className="table-full-width px-0 desktop-noScroll mt-0 pt-0">
                  <MainTable
                    // columns={personalize}
                    columns={formatPersonalization({ personalize, defaultConfig, userClientData, isSiteUser })}
                    rows={ordersToMap}
                    flipSort={flipSort}
                    sortBy={sortBy}
                    draggable
                    resizable
                    handleSaveDragAndResize={(personalizeArr) => {
                      setPersonalize(personalizeArr);
                    }}
                    cssClass="table-noresponsive"
                    sortDescending={sortDescending}
                    selectedRows={checkboxes}
                    tools={quickTools}
                    personalisationKey={PERSONALISATION_KEY.ORDER_PERSONALIZE}
                    individualRowCssClass={showRowColor}
                    dropDownOptions={getDropDownOptions()}
                    handleDropDownClick={(type, row) => {
                      if (type === "Audit Trail") {
                        appContext.showLogs({ ...row, title: "Order Logs", ordID: row.id });
                      }
                      if (type === "Test History") {
                        appContext.openPatientTestViewModal({ order: row, type: "order", uiType: "testHistory" });
                      }
                      if (type === "Req Form") {
                        showReqForm(row);
                      }
                    }}
                    customColumnCellRenderer={customRenderTD}
                    handleCellClick={handleCellClick}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <Loader />
      )}
      {/* {openCreator && (
        <OrderModal user={[newOrder]} appContext={appContext} handleClose={() => setOpenCreator(false)} />
      )} */}

      {openPersonalizationModal && (
        <PersonalizationModal
          data={formatPersonalization({ personalize, defaultConfig, userClientData, isSiteUser })}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}

      {openRestoredConfirmation && (
        <ConfirmationModal
          show={openRestoredConfirmation}
          title="Restore Test"
          message={`Are you sure, to restore this ${checkboxes.length} ${checkboxes.length > 1 ? "Orders" : "Order"} ?`}
          handleConfirm={handleConfirmRestore}
        />
      )}
      {cancelTestData.isConfirm && (
        <ConfirmationModal
          isPreRegister
          handleConfirm={handleConfirmation}
          title={`${cancelTestData.actionType} Order`}
          message={`Are you sure you want to ${cancelTestData.actionType} the ${
            allSelected ? totalRecord : checkboxes.length
          } record${checkboxes.length > 1 ? "s" : ""}?`}
          handleClose={() => setCancelTestsData({ isConfirm: false, reason: "" })}
          reasonObj={{ cancelTestData, setCancelTestsData }}
        />
      )}
    </div>
  );
};

export default React.memo(OrdersView, arePropsEqual);
