import React, { useEffect, useState, useCallback, useRef, memo, useContext } from 'react';
import Loader from 'components/Loader/Loader';
import MainTable from 'components/Table/MainTable';
import Select from 'react-select';
import api from 'api';
import { Button, Modal } from 'react-bootstrap';
import { PANEL_RESULT_TEST_PERSONALIZE } from 'constants/personalization';
import { useDispatch, useSelector } from 'react-redux';
import { resetStateResultState } from 'store/features/resultsTests/resultsTestSlice';
import { parseTestUnit } from 'utils';
import { customIsJsonString } from 'util/customLodash';
import { RESULT_SETTINGS_OPT_QUALITATIVE } from 'constant';
import { TYPE_OF_TEST } from 'constant';
import { customIsObject } from 'util/customLodash';

import { AppContext } from 'context/app-context';
import { v4 as uuidv4 } from 'uuid';
import {
  getSpecificResultPersonalize,
  getRangeByAgeOrGender,
  getRowResultColor,
  getOverallTestResultColor,
  isJSONString,
  toTitleCase,
  generateSimpleRefIntervalFromRanges,
} from 'utils';
import { TEST_PANEL_TYPES } from 'constant';
import { locationsSelectors } from 'store/features/locations/locationsSelectors';

import { ANTIBIOTIC_RESISTANCE_TYPE } from 'constant';

const TestResultInput = memo(({ row, onUpdateTestResult }) => {
  const inputRef = useRef(null);
  const handleBlur = (event) => {
    onUpdateTestResult(event.target.value, row.uniqueId);
  };

  const isQualitative = row?.possibleValues === TYPE_OF_TEST.QUALITATIVE;
  const isAdditionalResistance =
    row.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.ADDITIONAL;

  return (
    <td>
      {isAdditionalResistance || !isQualitative ? (
        <input
          className="w-100 modalInput"
          ref={inputRef}
          defaultValue={row?.testResult || ''}
          onBlur={handleBlur}
          onFocus={() => inputRef.current && inputRef.current.select()}
          key={row?.id}
          placeholder="Enter Result"
        />
      ) : (
        <Select
          className="w-100 siteSelector"
          options={RESULT_SETTINGS_OPT_QUALITATIVE}
          blurInputOnSelect={true}
          defaultValue={null}
          menuPlacement="auto"
          value={customIsJsonString(row?.testResult) && JSON.parse(row?.testResult)}
          placeholder="Select Result"
          onChange={(e) => onUpdateTestResult(e, row.uniqueId)}
        />
      )}
    </td>
  );
});

const ResultMarkInput = memo(({ row, onUpdateResultMark }) => {
  const resultInputRef = useRef(null);

  const handleBlur = (event) => {
    onUpdateResultMark(event.target.value, row.uniqueId);
  };

  const isDisable = (testData) => {
    if (!testData.testResult) {
      return true;
    }
    if (isJSONString(testData.testResult)) {
      const { value } = JSON.parse(testData.testResult);
      return value !== 'Detected';
    }
    return true;
  };

  return (
    <td>
      <input
        className="w-100 modalInput"
        ref={resultInputRef}
        defaultValue={row?.resultValue || ''}
        onBlur={handleBlur}
        onFocus={() => resultInputRef.current && resultInputRef.current.select()}
        key={row?.id}
        //disabled={row.typeOfTest === TYPE_OF_TEST.QUANTITATIVE || isDisable(row)}
        type="number"
        placeholder="CT Value"
      />
    </td>
  );
});

const PanelResultModal = ({ panelTests }) => {

  const [isLoading, setIsLoading] = useState(false);

  const [resultsData, setResultsData] = useState([]);

  const labLocations = useSelector(locationsSelectors);

  const managePersonalization = (testData) => {
    return getSpecificResultPersonalize(testData);
  };

  const appContext = useContext(AppContext);

  const dispatch = useDispatch();

  const addDefaultdResult = (list) => {
    return list.map((item) => {
      if (
        item.typeOfTest === TYPE_OF_TEST.QUALITATIVE &&
        item.antibioticResistanceType !== ANTIBIOTIC_RESISTANCE_TYPE.ADDITIONAL
      ) {
        return {
          ...item,
          testResult: JSON.stringify({ label: 'Not Detected', value: 'Not Detected' }),
        };
      }
      return item;
    });
  };

  const formatPanelTest = (panelData) => {
    const { patientAge, patientGender } = panelData;

    const formatedData = [];
    formatedData.push({ panelName: panelData.panelName, showEmptyRow: true, uniqueId: uuidv4() });

    panelData?.tests?.forEach((item) => {
      if (
        item.type === TEST_PANEL_TYPES.QUALITATIVE_WITH_PATHOGEN ||
        item.type === TEST_PANEL_TYPES.QUANTITATIVE_WITH_PATHOGEN
      ) {
        let selectedRange = getRangeByAgeOrGender({
          ranges: item.ranges,
          gender: patientGender,
          age: patientAge,
        });

        const reactiveTest = [
          {
            label: 'Resistance Genes',
            showEmptyRow: true,
            antibioticDivider: true,
          },
        ];

        const organismsTest = [
          {
            label: 'Bacteria/Organisms',
            showEmptyRow: true,
            antibioticDivider: true,
          },
        ];

        formatedData.push({
          testName: item.name,
          showEmptyRow: true,
          uniqueId: uuidv4(),
          type: item.type,
          testTypeId: item.id,
          code: item.code,
        });

        item.targetRanges.forEach((tItem) => {
          const formattedTest = {
            ...tItem,
            uniqueId: uuidv4(),
            mainTestTypeId: item.id,
            refInterval: selectedRange
              ? generateSimpleRefIntervalFromRanges({ ranges: selectedRange, testType: item.type })
              : 'Not Detected',
            ranges: selectedRange,
          };

          if (formattedTest.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.ORGANISM) {
            organismsTest.push(formattedTest);
          }
          if (formattedTest.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.RESISTANCE) {
            reactiveTest.push(formattedTest);
          }
        });

        if (organismsTest.length > 1) {
          formatedData.push(...organismsTest);
        }

        if (reactiveTest.length > 1) {
          formatedData.push(...reactiveTest);
        }
      } else if (
        item.type === TEST_PANEL_TYPES.QUALITATIVE_WITHOUT_PATHOGEN ||
        item.type === TEST_PANEL_TYPES.QUANTITATIVE_WITHOUT_PATHOGEN
      ) {
        const selectedRange = getRangeByAgeOrGender({
          ranges: item.ranges,
          gender: patientGender,
          age: patientAge,
        });

        formatedData.push({
          testName: item.name,
          units: { label: item.units, value: item.units },
          typeOfTest: item.typeOfTest,
          id: item.id,
          code: item.code,
          uniqueId: uuidv4(),
          refInterval: selectedRange
            ? generateSimpleRefIntervalFromRanges({ ranges: selectedRange, testType: item.type })
            : '',
          type: item.type,
          testTypeId: item.id,
          ranges: [selectedRange],
        });
      }

      if (item.type === TEST_PANEL_TYPES.PANEL) {
        formatedData.push({ panelName: item.panelName, showEmptyRow: true, uniqueId: uuidv4() });

        item.targetRanges.map((subItem) => {
          if (
            subItem.type === TEST_PANEL_TYPES.QUALITATIVE_WITH_PATHOGEN ||
            subItem.type === TEST_PANEL_TYPES.QUANTITATIVE_WITH_PATHOGEN
          ) {
            formatedData.push({
              testName: subItem.name,
              code: subItem.code,
              showEmptyRow: true,
              uniqueId: uuidv4(),
              type: subItem.type,
              testTypeId: subItem.id,
            });

            const reactiveTestSub = [
              {
                label: 'Resistance Genes',
                showEmptyRow: true,
                antibioticDivider: true,
              },
            ];

            const organismsTestSub = [
              {
                label: 'Bacteria/Organisms',
                showEmptyRow: true,
                antibioticDivider: true,
              },
            ];

            subItem.targetRanges.forEach((item) => {
              const formattedTest = {
                ...item,
                uniqueId: uuidv4(),
                mainTestTypeId: subItem.id,
                refInterval: selectedRange
                  ? generateSimpleRefIntervalFromRanges({
                    ranges: selectedRange,
                    testType: item.type,
                  })
                  : 'Not Detected',
                ranges: selectedRange,
              };

              if (formattedTest.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.ORGANISM) {
                organismsTestSub.push(formattedTest);
              }
              if (
                formattedTest.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.RESISTANCE
              ) {
                reactiveTestSub.push(formattedTest);
              }
            });

            if (organismsTestSub.length > 1) {
              formatedData.push(...organismsTestSub);
            }

            if (reactiveTestSub.length > 1) {
              formatedData.push(...reactiveTestSub);
            }
          } else if (
            subItem.type === TEST_PANEL_TYPES.QUALITATIVE_WITHOUT_PATHOGEN ||
            subItem.type === TEST_PANEL_TYPES.QUANTITATIVE_WITHOUT_PATHOGEN
          ) {
            formatedData.push({
              testName: subItem.name,
              code: subItem.code,
              uniqueId: uuidv4(),
              units: { label: subItem.units, value: subItem.units },
              typeOfTest: subItem.typeOfTest,
              id: subItem.id,
              type: subItem.type,
              testTypeId: isubItemtem.id,
            });
          }
        });
      }
    });

    setResultsData(addDefaultdResult(formatedData.flat()));
  };

  const formatTestWithoutPanel = (panelData) => {
    const { tests, ranges, patientAge, patientGender, type } = panelData;

    const selectedRange = getRangeByAgeOrGender({ ranges, gender: patientGender, age: patientAge });

    const formattedTest = { ...tests, uniqueId: uuidv4() };

    if (selectedRange) {
      const refInterval = generateSimpleRefIntervalFromRanges({
        ranges: selectedRange,
        testType: type,
      });
      formattedTest.ranges = [selectedRange];
      formattedTest.refInterval = refInterval;
    }

    setResultsData(addDefaultdResult([formattedTest]));
  };

  const formatMainTestWithPanel = (panelData) => {
    const { tests, ranges, patientAge, patientGender, type } = panelData;

    let selectedRange = getRangeByAgeOrGender({ ranges, gender: patientGender, age: patientAge });

    const formatedData = [];

    formatedData.push({ panelName: panelData.testName, showEmptyRow: true, uniqueId: uuidv4() });

    const reactiveTest = [
      {
        label: 'Resistance Genes',
        showEmptyRow: true,
        antibioticDivider: true,
      },
    ];

    const associatedTest = [];

    const organismsTest = [
      {
        label: 'Bacteria/Organisms',
        showEmptyRow: true,
        antibioticDivider: true,
      },
    ];

    const additionalTest = [
      {
        label: 'Additional Test',
        showEmptyRow: true,
        antibioticDivider: true,
      },
    ];

    tests?.forEach((item) => {
      const formattedTest = { ...item, uniqueId: uuidv4() };

      selectedRange = item?.ranges
        ? getRangeByAgeOrGender({ ranges: item.ranges, gender: patientGender, age: patientAge })
        : selectedRange;

      if (selectedRange) {
        let refInterval = generateSimpleRefIntervalFromRanges({
          ranges: selectedRange,
          testType: type,
          test: item,
        });
        refInterval = refInterval === '0 - 0' ? 'Not Estab.' : refInterval;
        formattedTest.ranges = [selectedRange];
        formattedTest.refInterval = refInterval;
      } else {
        let refInterval =
          item.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.ADDITIONAL ||
            item.possibleValues === TYPE_OF_TEST.QUANTITATIVE
            ? 'Not Estab.'
            : 'Not Detected';
        formattedTest.refInterval = refInterval;
      }

      if (formattedTest.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.ORGANISM) {
        organismsTest.push(formattedTest);
      }
      if (formattedTest.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.RESISTANCE) {
        reactiveTest.push(formattedTest);
      }

      if (formattedTest.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.ADDITIONAL) {
        additionalTest.push(formattedTest);
      }

      if (formattedTest.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.ASSOCIATED) {
        associatedTest.push(formattedTest);
      }

      // formatedData.push(formattedTest);
    });

    if (associatedTest.length > 0) {
      formatedData.push(...associatedTest);
    }
    if (organismsTest.length > 1) {
      formatedData.push(...organismsTest);
    }

    if (reactiveTest.length > 1) {
      formatedData.push(...reactiveTest);
    }
    if (additionalTest.length > 1) {
      formatedData.push(...additionalTest);
    }
    setResultsData(addDefaultdResult(formatedData));
  };

  useEffect(() => {
    if (panelTests?.hasOwnProperty('resultDetails')) {
      const parsedJson = JSON.parse(panelTests.resultDetails);
      setResultsData(parsedJson.map((item) => ({ ...item, uniqueId: uuidv4() })));
    } else if (panelTests.type === TEST_PANEL_TYPES.PANEL) {
      formatPanelTest(panelTests);
    } else if (
      panelTests.type === TEST_PANEL_TYPES.QUALITATIVE_WITHOUT_PATHOGEN ||
      panelTests.type === TEST_PANEL_TYPES.QUANTITATIVE_WITHOUT_PATHOGEN
    ) {
      formatTestWithoutPanel(panelTests);
    } else if (
      panelTests.type === TEST_PANEL_TYPES.QUALITATIVE_WITH_PATHOGEN ||
      panelTests.type === TEST_PANEL_TYPES.QUANTITATIVE_WITH_PATHOGEN ||
      panelTests.type === TEST_PANEL_TYPES.BOTH
    ) {
      formatMainTestWithPanel(panelTests);
    }
  }, [panelTests]);

  const handleClose = () => {
    dispatch(resetStateResultState());
  };

  const handleUpdateTestResult = useCallback((value, id) => {
    setResultsData((currentResultsData) => {
      const index = currentResultsData.findIndex((item) => item.uniqueId === id);

      if (index !== -1) {
        const newData = [...currentResultsData];

        const color = getRowResultColor({ value, dataRow: newData[index] });
        newData[index] = {
          ...newData[index],
          testResult: customIsObject(value) ? JSON.stringify(value) : value,
          resultColor: color,
          // resultValue: value.value === "Detected" ? newData[index]?.resultValue : "",
        };

        return newData;
      }
      return currentResultsData;
    });
  }, []);

  const handleUpdateResultMark = useCallback((value, id) => {
    setResultsData((currentResultsData) => {
      const index = currentResultsData.findIndex((item) => item.uniqueId === id);

      if (index !== -1) {
        const newData = [...currentResultsData];
        newData[index] = { ...newData[index], resultValue: value };
        return newData;
      }
      return currentResultsData;
    });
  }, []);

  const getResultValue = () => {
    const isPathogenTest =
      panelTests.type === TEST_PANEL_TYPES.QUALITATIVE_WITH_PATHOGEN ||
      panelTests.type === TEST_PANEL_TYPES.QUANTITATIVE_WITH_PATHOGEN;

    if (isPathogenTest) {
      return 'Details';
    }

    const resultValue = resultsData?.[0]?.testResult || null;

    return customIsJsonString(resultValue) ? JSON.parse(resultValue).value : 'Details';
  };

  const handleSaveResult = async () => {
    const isAllResultMarked = resultsData.filter((r) => !r.showEmptyRow).every((t) => t.testResult);

    if (!isAllResultMarked) {
      appContext.showErrorMessage(`Please marked the complete result`);
      return;
    }

    const resultColor = getOverallTestResultColor(resultsData);

    // Get From App Context
    const companyClient = appContext.clients[0];

    const clientAutoRelease = !!companyClient?.setting?.autoRelease;

    // Find From LabClinet
    const show = labLocations.find((item) => item.id === panelTests.siteID);

    const showAutoRelease = !!show?.setting?.autoRelease;

    setIsLoading(true);
    try {
      const updatedObj = {
        id: panelTests.id,
        resultDetails: resultsData,
        isAssociatedPanel: panelTests.isAssociatedPanel,
        type: panelTests.type,
        typeOfTest: panelTests.typeOfTest,
        result: getResultValue(),
        clientAutoRelease,
        showAutoRelease,
        resultColor,
      };

      await api.assingTestResult(updatedObj);

      handleClose();

      appContext.showSuccessMessage(`Result marked.`);
    } catch (error) {
      console.log('error', error);
      appContext.showErrorMessage(`Error in result marking.`);
    }
  };

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === 'testResult') {
      return row.showEmptyRow ? (
        <td key={`empty_${item.id}`} style={{ textAlign: 'center' }}></td>
      ) : (
        <TestResultInput row={row} onUpdateTestResult={handleUpdateTestResult} />
      );
    }

    if (item.itemKey === 'unit') {
      return (
        <td key={`unit_${item.id}`} style={{ textAlign: 'center' }}>
          {parseTestUnit(row?.units)}
        </td>
      );
    }
    if (item.itemKey === 'panelName') {
      return (
        <td
          key={`unit_${item.id}`}
          style={{
            fontWeight:
              row?.testName || row.hasOwnProperty('antibioticResistanceType') ? '600' : '700',
            paddingLeft:
              row?.testName || row.hasOwnProperty('antibioticResistanceType') ? '30px' : '10px',
            color:
              row?.testName || row.hasOwnProperty('antibioticResistanceType')
                ? 'rgb(72 69 69)'
                : 'black',
          }}
        >
          {row?.panelName || row?.name || row?.testName || ''}
        </td>
      );
    }

    if (item.itemKey === 'testResultMarks') {
      const isQualitative = row?.possibleValues === TYPE_OF_TEST.QUALITATIVE;
      const isAdditionalResistance =
        row.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.ADDITIONAL;

      if (row.showEmptyRow || isAdditionalResistance || !isQualitative) {
        return <td key={`empty_${item.id}`} style={{ textAlign: 'center' }}></td>;
      }

      return <ResultMarkInput row={row} onUpdateResultMark={handleUpdateResultMark} />;
    }
  };

  return (
    <Modal
      show
      animation={true}
      onHide={handleClose}
      style={{ paddingLeft: '0' }}
      centered
      size="3xl"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Result for - {panelTests?.barcode}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          paddingTop: 0,
        }}
      >
        {isLoading && <Loader />}

        <div style={{ background: '#f2f0f0', paddingLeft: '4px', paddingRight: '4px' }}>
          <MainTable
            cssClass="table-noresponsive"
            trDataWrapperHeader={'trDataWrapperHeader'}
            customColumnCellRenderer={customRenderTD}
            columns={managePersonalization(resultsData)}
            individualRowCssClass={(row) =>
              row?.resultColor === 'red' ? 'trDataWrapper isPositiveResult' : 'trDataWrapper'
            }
            // individualRowCssClass={(row) =>
            //   customIsJsonString(row?.testResult) && JSON.parse(row?.testResult).label === "Detected"
            //     ? "trDataWrapper isPositiveResult"
            //     : "trDataWrapper"
            // }
            rows={resultsData}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons"
          onClick={handleSaveResult}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PanelResultModal;
