import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { AppContext } from "context/app-context";
import { CANCEL_REASON_OPTIONS, CONFIG } from "constant";
import api from "api";


const CreateInstrumentModal = ({ handleClose, handleSaveChange, selectedValue }) => {
  const [platformData, setPlatFormData] = useState({ instrument: "", platform: "" });

  const appContext = useContext(AppContext);

  useEffect(() => {
    if (selectedValue) {
      setPlatFormData(selectedValue);
    }
  }, [selectedValue]);

  const handleSave = () => {
    if (!platformData.instrument) {
      appContext.showErrorMessage("Enter instrument name.");
      return;
    }
    if (!platformData.platform) {
      appContext.showErrorMessage("Enter instrument platform name.");
      return;
    }

    handleSaveChange(platformData);
  };



  return (
    <Modal
      show
      animation={true}
      onHide={() => handleClose()}
      backdrop="static"
      centered
      size={"md"}
      keyboard={true}
      className="second-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Create Instruments
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="row mb-3">
          <div className="col-lg-12 col-12 mb-3">
            <label className="modalLineHeaders mt-0">Instrument:</label>
            <input
              value={platformData.instrument}
              className="modalInput"
              placeholder="Enter Instrument Name"
              style={{
                width: "100%",
              }}
              onChange={(e) => setPlatFormData({ ...platformData, instrument: e.target.value })}
            />

            <div className="col-lg-12 col-12 mt-3">
              <label className="modalLineHeaders mt-0">Platform:</label>
              <input
                value={platformData.platform}
                className="modalInput"
                placeholder="Enter Platform Name"
                style={{
                  width: "100%",
                }}
                onChange={(e) => setPlatFormData({ ...platformData, platform: e.target.value })}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="headerButton" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="primary" className="modalButtons" onClick={handleSave}>
          Save
        </Button>

      </Modal.Footer>
    </Modal>
  );
};

export default CreateInstrumentModal;
