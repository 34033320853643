import React from 'react';

const ElementPositionDraggableView = ({ elementData = [], onReorder }) => {

    const handleDragStart = (e, index) => {
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', index);
    };

    const handleDragOver = (e, index) => {
        e.preventDefault();
    };

    const handleDrop = (e, dropIndex) => {
        e.preventDefault();
        const dragIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);

        if (isNaN(dragIndex) || dragIndex === dropIndex) {
            return;
        }

        const updatedItems = [...elementData];
        const [draggedItem] = updatedItems.splice(dragIndex, 1);
        updatedItems.splice(dropIndex, 0, draggedItem);

        const reorderedItems = updatedItems.map((item, index) => ({
            ...item,
            position: index + 1,
        }));

        onReorder(reorderedItems);
    };

    return (
        <div>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                <li className={`draggable-item draggable-item-gray`}>
                    <span>Element Name</span>
                    <span> Instrument Platform</span>
                </li>
                {elementData.map((item, index) => {
                    if (item?.setting?.platform?.length) {
                        return (
                            <li
                                key={item.id}
                                draggable
                                onDragStart={(e) => handleDragStart(e, index)}
                                onDragOver={(e) => handleDragOver(e, index)}
                                onDrop={(e) => handleDrop(e, index)}
                                className="draggable-item"
                            >
                                <span>{item.name}</span>
                                <span>
                                    {item?.setting?.platform
                                        ?.map((platform) => platform.label)
                                        .join(', ')}
                                </span>
                            </li>
                        )
                    }
                }


                )}
            </ul>
        </div>
    );
};

export default ElementPositionDraggableView;
